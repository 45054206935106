import React, { useState, useEffect } from "react";
import classNames from "classnames";
import SearchResult from "./_searchResultTypes/SearchResult";
import NewsResult from "./_searchResultTypes/NewsResult";
import { PulseLoader } from "react-spinners";

let SearchResults = (props, loading) => {
  const [showLoadMore, setShowLoadMore] = useState(false);

  let loaderClasses = classNames({
    "search-result-wrapper__loading": true,
    "is-active": props.loading
  });

  let loadMoreClasses = classNames({
    "sr-inner__load-more-wrapper": true,
    "is-active": showLoadMore
  });

  useEffect(() => {
    setShowLoadMore(props.items.TotalCount > props.take);
  }, [props]);

  return (
    <div className="search-result-wrapper">
      <div className={loaderClasses}>
        <PulseLoader
          size={15}
          sizeUnit={"px"}
          color={"#0d97f2"}
          loading={props.loading}
        />
      </div>
      <div className="sr-inner grid-1280">
        <div className="sr-inner__sort-row">
          {/* <span className="sr-inner__sort-row__heading">Sortera på:</span>
                    <a href="#" className="sr-inner__sort-row__option active">Datum</a>
                    <a href="#" className="sr-inner__sort-row__option">Relevans</a> */}
        </div>
        {props.items.Hits.map((hit, i) => {
          let key = i + hit.header;
          switch (props.items.typeOfSearch) {
            case "Search":
              return <SearchResult {...hit} key={key} id={i} />;
            case "News":
              return <NewsResult {...hit} key={key} id={i} />;
            default:
              return null;
          }
        })}
        <div className={loadMoreClasses}>
          <button
            onClick={() => props.changeTake()}
            className="sr-inner__load-more-wrapper__load-more"
          >
            Visa fler
            <i className="sr-inner__load-more-wrapper__load-more__icon icon-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
