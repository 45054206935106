import React from "react";
import { render } from "react-dom";
import { NewsArchivePage } from "./NewsArchivePage";

let mountNewsArchivePage = {
  append(root) {
    if (!root) return;
    const _root = root[0] != undefined ? root[0] : root;

    const component = render(<NewsArchivePage />, _root);
    return component;
  },

  init() {
    let newsArchivePage = document.getElementById("news-archive-page");

    this.append(newsArchivePage);
  },
};

export default mountNewsArchivePage;
