import React, { useEffect } from "react";
import anime from "animejs";

const AnimationText = ({ header, animation }) => {
  useEffect(() => {
    switch (animation) {
      case "fast":
        fast();
        break;
      case "slow":
        slow();
        break;
      default:
        break;
    }
  });
  const slow = () => {
    anime.timeline().add({
      targets:
        ".start-page-wrapper__top-image__text-wrapper__inner__text .letter",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 1350,
      delay: (el, i) => 110 * (i + 1),
    });
  };

  const splitHeader = () => {
    return header.split("").map((letter, index) => {
      return (
        <span aria-hidden="true" key={index} className="letter">
          {letter}
        </span>
      );
    });
  };
  const fast = () => {
    anime.timeline().add({
      targets:
        ".start-page-wrapper__top-image__text-wrapper__inner__text .letter",
      opacity: [0, 1],
      translateX: [40, 0],
      translateZ: 0,
      scaleX: [0.3, 1],
      easing: "easeOutExpo",
      duration: 3000,

      offset: "-=200",
      delay: (el, i) => 150 + 50 * i,
    });
  };

  return splitHeader();
};

export default AnimationText;
