import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';

import { QueryParamProvider } from 'use-query-params';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';

import reducers from '../../reducers';
import ModuleSearch from '../../modules/module-search/module-search';

const reducer = combineReducers({
    ...reducers
});

export const store = createStore(reducer, applyMiddleware(thunk));

class AppReactHandler {
    init() {
        this._mountReact('search', 'ModuleSearch', ModuleSearch);
    }

    _mountReact(pathname, elem, component) {
        if (document.getElementById(elem) === null) return;

        ReactDOM.render(
            <Provider store={store}>
                <BrowserRouter>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <Route name={pathname} path="*" component={component} />
                </QueryParamProvider>
                </BrowserRouter>
            </Provider>,
            document.getElementById(elem)
        );
    }
}

export default AppReactHandler;