import React, { useState, Fragment, useEffect } from "react";
//import classes from './HeaderSearch.css'
import SearchInput from "./SearchInput";
import SearchApi from "../../api/search.api";
import SuggestionsList from "./SuggestionsList";

const HeaderSearch = (props) => {
  // const [queryParam, setQueryParam] = useQueryParam("query", StringParam);

  const [userInput, setUserInput] = useState("");
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const onClick = (e) => {
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(e.currentTarget.innerText);
  };
  useEffect(() => {
    if (userInput.length) {
      const timer = setTimeout(
        () =>
          SearchApi.search(userInput, "", 10, "header").then((res) => {
            setFilteredSuggestions(
              res.items
                .filter(
                  (suggestion) =>
                    suggestion.header
                      .toLowerCase()
                      .indexOf(userInput.toLowerCase()) > -1
                )
                .slice(0, 4)
            ).catch((e) => console.error(e));
          }),
        300
      );

      setShowSuggestions(true);

      return () => clearTimeout(timer);
    } else {
      setFilteredSuggestions([]);
    }

    //setActiveSuggestion(0);
  }, [userInput]);

  const onChange = (e) => {
    setUserInput(e.currentTarget.value);
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
      setUserInput(filteredSuggestions[activeSuggestion].header);
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
      setUserInput(filteredSuggestions[activeSuggestion].header);
    }
  };

  return (
    <div className="search-box-header__inner__content__form">
      <SearchInput
        userInput={userInput}
        showSuggestions={showSuggestions}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      {showSuggestions && userInput && (
        <SuggestionsList
          userInput={userInput}
          suggestions={filteredSuggestions}
        />
      )}
      <button tabIndex="-1" aria-label="Sök" type="submit">
        <i className="icon-sok search-box-header__inner__content__form__icon"></i>
      </button>
    </div>
  );
};

export default HeaderSearch;
