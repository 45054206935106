    // Breakpoints
const currentBreakpoint = (breakpointToCompare, behaviour = '') => {
    const body = document.getElementsByTagName('body')[0];
    const currentBreakpoint = window.getComputedStyle(body, ':before').content.replace(/"/g, '');
    // Mirror of list in _settings.scss
    const breakpoints = [
      'small',
      'medium',
      'large',
      'xlarge',
      'xxlarge'
    ];
  
    if (behaviour === '') {
      return breakpointToCompare === currentBreakpoint;
    }
  
    if (behaviour !== '') {
      if (breakpointToCompare === currentBreakpoint) {
        return true;
      } else {
        const currentIndex = breakpoints.indexOf(currentBreakpoint);
        const compareIndex = breakpoints.indexOf(breakpointToCompare);
  
        if (behaviour === 'down') {
          return currentIndex < compareIndex;
        }
  
        if (behaviour === 'up') {
          return currentIndex > compareIndex;
        }
      }
    }
  
    return false;
  };

  export default {
      currentBreakpoint
  }