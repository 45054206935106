import React from "react";
import { render } from "react-dom";
import MenuReact from "./c-menu-react";
import utils from "../../framework/app.utils";

let MountMenuReact = {
  append(root, device) {
    if (!root) return;
    const _root = root[0] != undefined ? root[0] : root;
    const html = _root.innerText != "" ? _root.innerText : _root.textContent;
    let props = JSON.parse(html);

    const component = render(<MenuReact {...props} device={device} />, _root);
    return component;
  },

  init() {
    let menuReactMobile = document.getElementsByClassName("menu-react-mobile");
    let menuReactDesktop = document.getElementsByClassName(
      "menu-react-desktop"
    );
    if (menuReactMobile.length) {
      if (menuReactMobile.length > 1) {
        for (var i = 0; i < menuReactMobile.length; i++) {
          this.append(menuReactMobile, "mobile");
        }
      } else {
        this.append(menuReactMobile, "mobile");
      }
    }
    if (menuReactDesktop.length) {
      if (menuReactDesktop.length > 1) {
        for (var i = 0; i < menuReactDesktop.length; i++) {
          this.append(menuReactDesktop, "desktop");
        }
      } else {
        this.append(menuReactDesktop, "desktop");
      }
    }
  },
};

export default MountMenuReact;
