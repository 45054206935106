const playButtonStart = {
  init() {
    let startVideos = document.getElementsByClassName(
      "start-page-wrapper__top-image__vid"
    );
    let video = startVideos[0];
    let playButton = document.getElementById("play-pause-button");
    let playIcon = document.getElementById("play-pause-icon");
    let playText = document.getElementById("play-pause-text");

    if (playButton !== null) {
      playButton.onclick = function () {
        let playing = playButton.getAttribute("data-playing");

        if (playing === "true") {
          this.setAttribute("data-playing", "false");
          playIcon.classList.remove("icon-pause");
          playIcon.classList.add("icon-play");
          playText.innerHTML = "Spela";
          playButton.setAttribute("aria-label", "Spela filmen");
          video.pause();
        } else {
          this.setAttribute("data-playing", "true");
          playIcon.classList.remove("icon-play");
          playIcon.classList.add("icon-pause");
          playText.innerHTML = "Pausa";
          playButton.setAttribute("aria-label", "Pausa filmen");

          video.play();
        }
      };
    }
  },
};

export default playButtonStart;
