import React from "react";
import { render } from "react-dom";
import AutoComplete from "./AutoComplete";

let mountSearchBlock = {
  append(root) {
    if (!root) return;
    const _root = root[0] != undefined ? root[0] : root;

    const component = render(<AutoComplete />, _root);
    return component;
  },

  init() {
    let startHeroBlock = document.getElementById("search-box-input");

    this.append(startHeroBlock);
  },
};

export default mountSearchBlock;
