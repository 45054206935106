import React from "react";
import { render } from "react-dom";
import PatientFlow from "./c-patient-flow-block";

let MountPatientFlow = {
  append(root) {
    if (!root) return;
    const _root = root[0] != undefined ? root[0] : root;
    const html = _root.innerText != "" ? _root.innerText : _root.textContent;
    let props = JSON.parse(html);

    const component = render(<PatientFlow {...props} />, _root);
    return component;
  },

  init() {
    let patientFlowBlocks = document.getElementsByClassName(
      "patient-flow-block"
    );
    if (!patientFlowBlocks.length) return false;
    if (patientFlowBlocks.length > 1) {
      for (var i = 0; i < patientFlowBlocks.length; i++) {
        this.append(patientFlowBlocks[i]);
      }
    } else {
      this.append(patientFlowBlocks);
    }
  }
};

export default MountPatientFlow;
