import React, { useState, useEffect } from "react";
import classNames from "classnames";
import NewsResult from "../../modules/module-search/_partials/_searchResultTypes/NewsResult";
import { PulseLoader } from "react-spinners";
import SearchApi from "../../api/search.api";

export const NewsArchivePage = () => {
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [take, setTake] = useState(10);
  useEffect(() => {
    SearchApi.search("", "", take, "news").then((res) => {
      setItems(res.items).catch((e) => console.error(e));
    }),
      setLoaded(true);
  }, [take]);
  let loaderClasses = classNames({
    "search-result-wrapper__loading": true,
    "is-active": !loaded,
  });

  let loadMoreClasses = classNames({
    "sr-inner__load-more-wrapper": true,
    "is-active": showLoadMore,
  });

  useEffect(() => {
    setShowLoadMore(items.TotalCount > take);
  }, [items]);

  return (
    <div className="search-result-wrapper">
      <div className={loaderClasses}>
        <PulseLoader
          size={15}
          sizeUnit={"px"}
          color={"#0d97f2"}
          loading={!loaded}
        />
      </div>
      <div className="sr-inner grid-1280">
        <div className="sr-inner__sort-row"></div>

        {loaded && items.Hits != undefined
          ? items.Hits.map((hit, i) => {
              let key = i + hit.header;

              return <NewsResult {...hit} key={key} id={i} />;
            })
          : null}
        <div className={loadMoreClasses}>
          <button
            onClick={() => setTake(take + 10)}
            className="sr-inner__load-more-wrapper__load-more"
          >
            Visa fler
            <i className="sr-inner__load-more-wrapper__load-more__icon icon-plus"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
