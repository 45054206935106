import React, { useState, useEffect, Fragment } from "react";
import RecursiveItem from "./Item";

let Item = ({ page }) => {
  let childPage = page.page != undefined ? page.page : page;
  let childPages = page.childPages;
  let isActive = childPage.isActive;
  let isCurrentDocument = childPage.isCurrentDocument;
  let buttonClass = isActive
    ? "expand-children-button open-container tabindex-value"
    : "expand-children-button closed-container tabindex-value";
  return childPages != undefined && childPages.length ? (
    <Fragment>
      <div className="expandable-inner-link">
        <a
          tabIndex="-1"
          href={childPage.nodeAliasPath}
          style={
            isCurrentDocument
              ? { fontWeight: "bold" }
              : { fontWeight: "normal" }
          }
        >
          {childPage.documentName}
        </a>
        <button
          tabIndex="-1"
          className={buttonClass}
          aria-expanded={isActive ? "true" : "false"}
        ></button>
      </div>
      <div
        className="expanded-children"
        aria-hidden={isActive ? "false" : "true"}
      >
        {childPages.map((e, i) => (
          <RecursiveItem key={i} page={e} />
        ))}
      </div>
    </Fragment>
  ) : (
    <a
      href={childPage.nodeAliasPath}
      style={
        isCurrentDocument ? { fontWeight: "bold" } : { fontWeight: "normal" }
      }
      className="menu-content-left__box-wrapper__box__inner__link-wrapper__link tabindex-value"
      tabIndex="-1"
    >
      {childPage.documentName}
    </a>
  );
};

export default Item;
