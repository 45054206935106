import React from 'react';
import FilterButton from './FilterButton';
import { useState, useEffect } from 'react';
import classNames from 'classnames';

let SearchFilter = ({header, value, buttons, onChange}) => {
    const [ activeFilters, setActiveFilters ] = useState([]);
    const [ filterName, setFilterName ] = useState(value);
    const [ fadeIn, setFadeIn ] = useState(false);

    let onFilterClick = (filterObj) => {
        const { val, key } = filterObj;
        let list = activeFilters;
        let add = false;
        if (val) {
            if (list.includes(key)) {
                list = removeFilterValue(list, key);
            }
            else {
                setActiveFilters(list.concat(key));
                add = true;
            }
        }
        else {
            list = removeFilterValue(list, key);
        }
        updateActiveFilters(list, key, add)
    }

    let removeFilterValue = (list, key) => {
        let indexToRemove = list.indexOf(key);
        if (indexToRemove > -1) {
            list.splice(indexToRemove, 1);
        }
        return list;
    }

    let updateActiveFilters = (list, val, add) => {
        if (add) list = list.concat(val); 
        onChange(list, filterName);
    }

    useEffect(() => {
        {buttons.map((button, i) => {
            const { active, value } = button;
            if (active) {
                let list = activeFilters;
                if (!list.includes(value)) setActiveFilters(list.concat(value));
            }
        })}
    }, []);

    useEffect(() => {
        if (buttons.length) {
            setTimeout(() => {
                setFadeIn(true);
            }, 100);
        }
    });

    let itemClass = classNames({
        'filterbutton-wrapper-inner__filter': true,
        'fade-in': fadeIn
    });

    return (
        <div className={itemClass}>
            <p className="filterbutton-wrapper-inner__filter__heading">{header}</p>
            <div className="filterbutton-wrapper-inner__filter__btn-wrapper">
               {buttons.map((button, i) => {
                    return <FilterButton {...button} key={i} onClick={(filterObj) => onFilterClick(filterObj)} />;
               })}
            </div>
        </div>
    );   
};

export default SearchFilter;