import request from "superagent";
import Promise from "promise";

export default {
  search(query, filters, take, header) {
    let params = {
      url: "/search/SearchResultModelService",
      query: typeof query === "undefined" ? "" : query,
      filters: typeof filters === "undefined" ? "" : filters,
      header: header,
      take: take,
    };

    let targetUrl = `${params.url}?searchText=${params.query}&filters=${filters}&take=${take}&header=${header}`;
    //let targetUrl = '/json/mock_search.json';

    return new Promise((resolve, reject) => {
      request
        .get(targetUrl)
        .set("Accept", "application/json")
        .end((error, response) => {
          if (response.body) {
            resolve(response.body);
          } else {
            reject("Error");
          }
        });
    });
  },
};
