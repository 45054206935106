import React from "react";
import { render } from "react-dom";
import HeroBlock from "./hero-block";

let mountHeroBlock = {
  append(root) {
    if (!root) return;
    const _root = root[0] != undefined ? root[0] : root;
    const html = _root.innerText != "" ? _root.innerText : _root.textContent;
    let props = JSON.parse(html);

    const component = render(<HeroBlock {...props} />, _root);
    return component;
  },

  init() {
    let startHeroBlock = document.getElementsByClassName(
      "start-page-wrapper__top-image"
    );
    // startHeroBlock.style.display = "none";
    if (!startHeroBlock.length) return false;
    if (startHeroBlock.length > 1) {
      for (var i = 0; i < startHeroBlock.length; i++) {
        this.append(startHeroBlock[i]);
      }
    } else {
      this.append(startHeroBlock);
    }
  }
};

export default mountHeroBlock;
