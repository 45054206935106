import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDebounce } from "use-debounce";
import classNames from "classnames";
import CountUp from "react-countup";

let SearchBar = ({
  searchText,
  totalCount,
  typeOfSearch,
  onChange,
  loading
}) => {
  const [text, setText] = useState(searchText);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(loading);
  const searchInput = useRef(null);

  const [value] = useDebounce(text, 1000);

  let onSearchInputChange = target => {
    var newVal = target.value;
    triggerChange(newVal);
  };

  let triggerChange = query => {
    if (query == text || !query.length) return false;
    setIsLoading(true);
    setText(query);
  };

  useEffect(() => {
    if (!value.length) return;
    onChange(value);
  }),
    [value];

  useEffect(() => {
    if (totalCount != count) {
      setTimeout(() => {
        setCount(totalCount);
      }, 1000);
    }
    setIsLoading(false);
  });

  let imageUrl = document.getElementById("imageUrl");
  const hasImage = imageUrl.getAttribute("data-param") != "";
  let imagePosition = document.getElementById("imagePosition")
  const hasPosition = imageUrl.getAttribute("data-param") != "";

  const backgroundStyle = hasImage ? imageUrl.getAttribute("data-param") : "";
  imagePosition = hasPosition ? imagePosition.getAttribute("data-param") : "";

  const textStyle = hasImage ? { color: "white" } : {};
    const divStyle =
        backgroundStyle != ""
            ? {
                background: `linear-gradient(to bottom right, rgba(13, 151, 242, 0.9) 0%, rgba(14, 60, 124, 0.9) 50%),url(${backgroundStyle})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: `${imagePosition}`
        }
      : {};

  let classBeginning = typeOfSearch == "News" ? "sf" : "saf";
  let resultTextClass = classNames({
    [`${classBeginning}-wrapper-inner__search__results`]: true,
    "is-loading": isLoading
  });

  return (
    <div className={`${classBeginning}-wrapper`} style={divStyle}>
      <div className={`${classBeginning}-wrapper-inner`}>
        <h1 style={textStyle}>Sökresultat</h1>

        <div className={`${classBeginning}-wrapper-inner__search`}>
          <div className={`${classBeginning}-wrapper-inner__search__input`}>
            <input
              type="search"
              ref={searchInput}
              placeholder="Sök..."
              defaultValue={searchText}
              className={`${classBeginning}-wrapper-inner__search__input__field`}
              onChange={e => onSearchInputChange(e.target)}
            />
            <i
              tabIndex="0"
              aria-hidden="true"
              className={`${classBeginning}-wrapper-inner__search__input__icon icon-search`}
              onClick={() => onSearchInputChange(searchInput.current)}
            ></i>
          </div>
          <p className={resultTextClass} style={textStyle}>
            <CountUp start={count} end={totalCount}>
              {({ countUpRef, start }) => (
                <Fragment>
                  Du har fått{" "}
                  <span
                    ref={countUpRef}
                    className={`${classBeginning}-wrapper-inner__search__results__amount`}
                  ></span>
                </Fragment>
              )}
            </CountUp>
            träffar på "
            <span
              className={`${classBeginning}-wrapper-inner__search__results__word`}
            >
              {searchText}
            </span>
            "
          </p>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
