import React from "react";
//import classes from './mountIcons.css'
import { render } from "react-dom";

import SetIcons from "./setIcons";

let mountIcons = {
  append(root) {
    if (!root) return;
    const _root = root[0] != undefined ? root[0] : root;
    const html = _root.innerText != "" ? _root.innerText : _root.textContent;
    let props = JSON.parse(html);

    const component = render(<SetIcons {...props} />, _root);
    return component;
  },

  init() {
    let courseListingBlock = document.getElementsByClassName(
      "c-icon-link-block__link-icons"
    );
    if (!courseListingBlock.length) return false;
    if (courseListingBlock.length > 1) {
      for (var i = 0; i < courseListingBlock.length; i++) {
        this.append(courseListingBlock[i]);
      }
    } else {
      this.append(courseListingBlock);
    }
  },
};

export default mountIcons;
