import React, { useState, useEffect } from "react";
import classNames from "classnames";

let SearchResult = ({ header, image, tags, url, text, id }) => {
  const [fadeIn, setFadeIn] = useState(false);

  useState(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, (id + 1) * (id > 9 ? (id > 19 ? id : 30) : 80));
  });

  let resultStyle = {
    opacity: fadeIn ? 1 : 0,
    transform: fadeIn ? "translateY(0)" : "translateY(30rem)",
  };

  return (
    <a href={url} className="sr-inner__result-box" style={resultStyle}>
      {image.length ? <img src={image} /> : null}
      <div className="sr-inner__result-box__left">
        <h3 className="sr-inner__result-box__left__heading">{header}</h3>
        <p className="sr-inner__result-box__left__text">{text}</p>
        <div className="sr-inner__result-box__left__tag-wrapper">
          {tags.map(({ name, url, CatClass }, i) => {
            let key = i + name;
            let tagClass = classNames({
              "sr-inner__result-box__left__tag": true,
              [`${CatClass}`]: true,
            });
            return (
              <div key={key} className={tagClass}>
                {name}
              </div>
            );
          })}
        </div>
      </div>
      <div className="sr-inner__result-box__right">
        <i className="sr-inner__result-box__right__icon icon-right-open"></i>
      </div>
    </a>
  );
};

export default SearchResult;
