import React, { useState, useEffect } from "react";
import PauseButton from "./pauseButton";
import styled, { css } from "styled-components";
import Text from "./partials/Text";

const Image = styled.div`
  ${({ imgSrc }) =>
    imgSrc.length &&
    css`
      background-image: url(${imgSrc});
    `};
  ${({ imagePosition }) =>
    imagePosition.length &&
    css`
      background-position: ${imagePosition};
    `};
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 60vh;
  display: flex;
  position: relative;
`;
const Video = styled.video`
  min-height: 460px;
  background-size: cover;
  background-repeat: no-repeat;
`;

const setOpacity = () => {
  var hero = document.getElementById("start-page-hero");
  hero.style.opacity = 1;
};

const heroBlock = ({ videoUrl, header, animation, imgSrc, imagePosition }) => {
  {
    setOpacity();
  }
  return videoUrl.length ? (
    <React.Fragment>
      <Video
        autoPlay
        loop
        muted
        playsInline
        className="start-page-wrapper__top-image__vid video"
        width="100%"
        height="100%"
        data-object-fit="cover"
        aria-label={header}
      >
        <source src={videoUrl} type="video/mp4" />
      </Video>
      <Text header={header} animation={animation} />
      <PauseButton pauseText="Pausa" />
    </React.Fragment>
  ) : (
    <Image
      className="start-page-wrapper__top-image__img"
      imgSrc={imgSrc}
      imagePosition={imagePosition}
    >
      <Text header={header} animation={animation} />
    </Image>
  );
};

export default heroBlock;
