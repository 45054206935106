module.exports={
    "header": "Något gick fel",
    "text": "Nedan har vi listat ett par tjänster som vi tror skulle passa dig. Du kan också skicka in en spontanansökan eller fylla i dina uppgifter så får du ett mail när vi har ett ligt jobb som passar dina preferenser.",
    "secondHeader": "Tjänster som passar dig",
    "sendButtonText": "Skicka spontanansökan",
    "educations": [
    ],
    "jobs": [
    
    ]
}