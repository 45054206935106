const playButtonStandard = {
  init() {
    let startVideos = document.getElementsByClassName(
      "landing-page-wrapper__hero__vid"
    );
    let video = startVideos[0];
    let playButton = document.getElementById("standard-play-pause-button");
    let playIcon = document.getElementById("standard-play-pause-icon");
    let playText = document.getElementById("standard-play-pause-text");

    if (playButton !== null) {
      playButton.onclick = function () {
        let playing = playButton.getAttribute("data-playing");
        if (playing === "true") {
          this.setAttribute("data-playing", "false");
          playIcon.classList.remove("icon-pause");
          playIcon.classList.add("icon-play");
          playButton.setAttribute("aria-label", "Spela filmen");

          playText.innerHTML = "Spela";
          video.pause();
        } else {
          this.setAttribute("data-playing", "true");
          playIcon.classList.remove("icon-play");
          playIcon.classList.add("icon-pause");
          playButton.setAttribute("aria-label", "Pausa filmen");

          playText.innerHTML = "Pausa";
          video.play();
        }
      };
    }
  },
};

export default playButtonStandard;
