import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import styled, { css } from "styled-components";

const ResultWrapper = styled.a`
  .lazy-background {
    background-image: ${({ imageUrl, visible }) =>
      visible ? `url(/${imageUrl})` : ``};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
`;

let SearchResult = ({ header, image, tags, url, text, id, altText, date }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if ("IntersectionObserver" in window) {
      let lazyBackgroundObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            setVisible(true);
            lazyBackgroundObserver.unobserve(entry.target);
          }
        });
      });
      if (imageRef.current != undefined) {
        lazyBackgroundObserver.observe(imageRef.current);
      }
    }
  }, []);

  const imageRef = useRef();
  const [fadeIn, setFadeIn] = useState(false);
  useState(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, (id + 1) * (id > 9 ? (id > 19 ? id : 30) : 80));
  });
  let imageUrl = image.slice(2);

  let resultStyle = {
    opacity: fadeIn ? 1 : 0,
    transform: fadeIn ? "translateY(0)" : "translateY(15rem)",
  };

  return (
    <ResultWrapper
      href={url}
      className="sr-inner__result-box"
      style={resultStyle}
      imageUrl={imageUrl}
      visible={visible}
    >
      {imageUrl.length ? (
        <div
          ref={imageRef}
          className="news-archive-news-image lazy-background"
          role="img"
          aria-label={altText}
        ></div>
      ) : null}
      <div className="sr-inner__result-box__left">
        <h2 className="sr-inner__result-box__left__heading">{header}</h2>
        <p className="sr-inner__result-box__left__text">{text}</p>
        {tags.map(({ name, url, CatClass }) => {
          let tagClass = classNames({
            "sr-inner__result-box__left__tag": true,
            [`${CatClass}`]: true,
          });
          return <div className={tagClass}>{name}</div>;
        })}
        <div className="sr-inner__result-box__left__date">
          <i className="icon-calendar-empty" aria-hidden="true"></i>
          {date}
        </div>
      </div>
      <div className="sr-inner__result-box__right">
        <i className="sr-inner__result-box__right__icon icon-right-open"></i>
      </div>
    </ResultWrapper>
  );
};

export default SearchResult;
