import React, {useState, useEffect, useRef, Fragment} from 'react';
import classNames from 'classnames';

let Question = ({question, correctAnswer, id, optionClick, show, answerText}) => {
    const [enter, setEnter] = useState(false);
    const questionElm = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setEnter(show);
        }, 300);
    })

    let classes = classNames({
        "enter": enter,
        "job-form__questions__question": true
    });

    let handleClick = (answer, id, correct) => {
        optionClick(answer, id, correct, answerText);
    }

    return (
        show 
            ? 
            <div className={classes} ref={questionElm}>
                <h4 className="job-form__questions__question__header">{question}</h4>
                <div className="job-form__questions__question__options-wrapper">
                    <input type="button" value="Nej" onClick={() => handleClick("Nej", id, "Nej" == correctAnswer)}></input>
                    <input type="button" value="Ja" onClick={() => handleClick("Ja", id, "Ja" == correctAnswer)}></input>
                </div>
              </div>
            : null
    );
};

export default Question;