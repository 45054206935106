import React from "react";
import AnimationText from "../AnimationText";

const Text = ({ header, animation }) => {
  return (
    <React.Fragment>
      <div className="start-page-wrapper__top-image__text-wrapper ">
        <div className="start-page-wrapper__top-image__text-wrapper__inner">
          <h1
            style={{
              opacity: 0,
              zIndex: 0,
              height: 0,
              width: 0,
            }}
          >
            {header}
          </h1>

          <p
            aria-hidden="true"
            className="start-page-wrapper__top-image__text-wrapper__inner__text"
          >
            <AnimationText header={header} animation={animation} />
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Text;
