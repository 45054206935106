import React, { useState, Fragment } from "react";

//import classes from './Course.css'

const Course = ({
  isMeetup,
  id,
  Type,
  StartDate,
  StartMonth,
  Link,
  Title,
  Description,
  Location,
  NumberOfOccasions,
  BackgroundImageUrl,
  NodeAliasPath,
}) => {
  const [fadeIn, setFadeIn] = useState(false);
  useState(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, id * 2 * (id > 9 ? (id > 19 ? id : 30) : 80));
  });
  let limitText = function (text, limit) {
    if (text.length > limit) {
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === " " &&
          (text.charAt(i - 1) != "," ||
            text.charAt(i - 1) != "." ||
            text.charAt(i - 1) != ";")
        ) {
          return text.substring(0, i) + "...";
        }
      }
      return text.substring(0, limit) + "...";
    } else return text;
  };

  let resultStyle = {
    opacity: fadeIn ? 1 : 0,
    transform: fadeIn ? "translateY(0rem)" : "translateY(500rem)",
  };
  let imageStyle = {
    backgroundImage: `url(${BackgroundImageUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  };
  return (
    <a href={NodeAliasPath} className="course-list__inner" style={resultStyle}>
      <div
        className="course-list__inner__image-wrapper"
        style={imageStyle}
      ></div>

      <div className="course-list__inner__info-wrapper">
        <div className="course-list__inner__info-wrapper__left">
          <div className="course-list__inner__info-wrapper__left__text">
            <h2 className="course-list__inner__info-wrapper__left__heading">
              {Title}
            </h2>
            <p className="course-list__inner__info-wrapper__left__ingress">
              <span
                className="content"
                dangerouslySetInnerHTML={{
                  __html: limitText(Description, 100),
                }}
              ></span>
            </p>
          </div>
          <div className="course-list__inner__info-wrapper__left">
            <div className="course-list__inner__info-wrapper__left__row">
              <div className="course-list__inner__info-wrapper__left__row__item">
                <i className="icon-location"></i>
                <p className="course-list__inner__info-wrapper__left__row__item__text">
                  {isMeetup ? Location : "E-kurs"}
                </p>
              </div>

              {isMeetup && (
                <div className="course-list__inner__info-wrapper__left__row__item">
                  <i className="icon-cw"></i>
                  <p className="course-list__inner__info-wrapper__left__row__item__text">
                    {NumberOfOccasions} tillfällen
                  </p>
                </div>
              )}
              {isMeetup && (
                <div className="course-list__inner__info-wrapper__left__row__item">
                  <i className="icon-clock"></i>
                  <p className="course-list__inner__info-wrapper__left__row__item__text">
                    {StartDate}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="course-list__inner__info-wrapper__right">
          <div className="course-list__inner__info-wrapper__right__button-wrapper">
            <div value="Till kurssidan">Läs mer</div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Course;
