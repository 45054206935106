import React, {useState, useEffect, useRef, Fragment} from 'react';
import classNames from 'classnames';

let Job = ({header, text, link, place, day, month, extentOfJob, profession, employmentType, buttonText, id, lastApplyText}) => {
    const [active, setActive] = useState(false);
    
    useEffect(() => {
        setTimeout(() => {
            setActive(true);
        }, id);    
    }, []);

    let classes = classNames({
        "job-form__result-wrapper__job": true,
        'is-active': active
    });

    let handleClick = () => {
        window.open(link, "_blank");
    }

    return (
        <div className={classes}>
            <div className="job-form__result-wrapper__job__date-container">
                <p className="job-form__result-wrapper__job__date-container__lastApply">{lastApplyText}</p>
                <p className="job-form__result-wrapper__job__date-container__day">{day}</p>
                <p className="job-form__result-wrapper__job__date-container__month">{month}</p>
            </div>
            <div className="job-form__result-wrapper__job__text-container">
                <h4 className="job-form__result-wrapper__job__text-container__header">{header}</h4>
                <div className="job-form__result-wrapper__job__text-container__meta-data">
                    <p className="job-form__result-wrapper__job__text-container__meta-data__place">
                        <i className="icon icon-location"></i>
                        {place}
                    </p>
                    <p className="job-form__result-wrapper__job__text-container__meta-data__profession">
                        <i className="icon icon-clipboard"></i>
                        {profession}
                    </p>
                    <p className="job-form__result-wrapper__job__text-container__meta-data__extend">
                        <i className="icon icon-cw"></i>
                        {extentOfJob}
                    </p>
                    <p className="job-form__result-wrapper__job__text-container__meta-data__type">
                        <i className="icon icon-handshake-o"></i>
                        {employmentType}
                    </p>
                </div>
                <p className="job-form__result-wrapper__job__text-container__text">{text}</p>
                <p className="job-form__result-wrapper__job__text-container__lastApplytext">{`${lastApplyText}: ${day} ${month}`}</p>
            </div>
            <div className="job-form__result-wrapper__job__button-container">
                <input type="button" onClick={() => handleClick()} className="job-form-button job-form-button--green job-form__result-wrapper__job__button-container__button" value={buttonText} />
            </div>
        </div>
    );
};

export default Job;