import React, { useEffect, useState, Fragment } from "react";
//import classes from './element.css'
import request from "superagent";
import Promise from "promise";

export default {
  getData(filter) {
    return new Promise((resolve, reject) => {
      request
        .get(`balooapi/${filter}`)
        .set("Accept", "application/json")
        .end((error, response) => {
          if (response.ok) {
            resolve(response.body);
          } else {
            reject(error);
          }
        });
    });
  },
};
