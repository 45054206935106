import React, { Fragment } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import chroma from "chroma-js";

//import classes from './FilterBar.css'

const FilterBar = ({ handleClick, value }) => {
  const options = [
    { value: "GetAllCourses", label: "Alla" },
    { value: "GetCourses", label: "Kurser" },
    { value: "GetECourses", label: "E-kurser" },
  ];

  const colourStyles = {
    control: (styles, isSelected) => ({
      ...styles,
      backgroundColor: "#e6ebf1",
      height: 40,
      minHeight: 30,
      width: 250,
      // color: "#575757",
      // fontWeight: "bold",

      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.5)",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#e6ebf1",
    }),
    singleValue: (style) => ({
      color: "#575757",
      fontWeight: "bold",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma("#e6ebf1");
      return {
        ...styles,
        padding: "5px",
        color: isSelected ? "white" : "#575757",
        fontWeight: "bold",

        // ":hover": {
        //   color: "#1468a0"
        // },
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    placeholder: (base) => ({
      color: "#575757",
      fontWeight: "bold",
    }),
    dropdownIndicator: (base, state) => ({
      width: 35,
    }),
  };
  const CaretDownIcon = () => {
    let style = {
      width: 50,
      marginLeft: 5,
      fontSize: 20,
      color: "#575757",
    };
    return <i style={style} className="icon-sliders"></i>;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  return (
    <React.Fragment>
      <Select
        className="cl-inner__control__filterBy__select"
        classNamePrefix="select"
        options={options}
        styles={colourStyles}
        components={{ DropdownIndicator }}
        onChange={(val) => handleClick(val)}
        placeholder="Filtrera"
      />
    </React.Fragment>
  );
};

export default FilterBar;
