import * as actionTypes from "../consts/action-types";
import SearchApi from "../api/search.api";

export const get = ({ query, filters, take }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH__LOADING,
      payload: true,
    });

    if (!query.length) {
      dispatch({
        type: actionTypes.SEARCH__GET,
        payload: {
          items: {
            Hits: [],
            TotalCount: 0,
          },
          filters: [],
          query: "",
          take: 10,
        },
      });

      dispatch({
        type: actionTypes.SEARCH__LOADING,
        payload: false,
      });
      return;
    }
    SearchApi.search(query, filters, take)
      .then((res) => {
        dispatch({
          type: actionTypes.SEARCH__GET,
          payload: {
            items: res.items,
            query: query,
            filters: res.filters,
            loading: false,
            take: res.take,
          },
        });

        dispatch({
          type: actionTypes.SEARCH__LOADING,
          payload: false,
        });
      })
      .catch((e) => console.error(e));
  };
};
