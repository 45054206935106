import React from "react";
import { render } from "react-dom";
import { FAQBlock } from "./FaqBlock";

let mountFaqBlock = {
  append(root) {
    if (!root) return;
    const _root = root[0] != undefined ? root[0] : root;
    const html = _root.innerText != "" ? _root.innerText : _root.textContent;
    let props = JSON.parse(html);

    const component = render(<FAQBlock {...props} />, _root);
    return component;
  },

  init() {
    let faqBlock = document.getElementsByClassName("faq-block");
    if (!faqBlock.length) return false;
    if (faqBlock.length > 1) {
      for (var i = 0; i < faqBlock.length; i++) {
        this.append(faqBlock[i]);
      }
    } else {
      this.append(faqBlock);
    }
  },
};

export default mountFaqBlock;
