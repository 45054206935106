import React from "react";
import { render } from "react-dom";
import CourseListPage from "./CourseListPage";

let mountCourseListingBlock = {
  append(root) {
    if (!root) return;
    const _root = root[0] != undefined ? root[0] : root;

    const component = render(<CourseListPage />, _root);
    return component;
  },

  init() {
    let courseListBlock = document.getElementById(
      "course-listing-page-wrapper"
    );
    this.append(courseListBlock);
  },
};

export default mountCourseListingBlock;
