import React, { Fragment, useState } from "react";
//import classes from './setIcons.css'

const setIcons = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const setOpacity = () => {
    var hero = document.getElementById(props.linkId);
    hero.style.opacity = 1;
  };

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };
  setOpacity();

  return (
    <Fragment>
      {props.hasIcon.length ? (
        <div
          className="c-icon-link-block__link-block__wrapper"
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          {isHovered && (
            <object
              className="c-icon-link-block__link-block__icon icon-object"
              type="image/svg+xml"
              data={props.animatedIcon}
            >
              {/* <i className={props.icon}></i> */}
            </object>
          )}

          {/* <span className="c-icon-link-block__link-block__text">
            {props.name}
          </span> */}
        </div>
      ) : (
        <div className="c-icon-link-block__link-block__wrapper">
          <i className={props.icon}></i>
          <span className="c-icon-link-block__link-block__text">
            {props.name}
          </span>
        </div>
      )}
    </Fragment>
  );
};

export default setIcons;
