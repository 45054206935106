import * as actionTypes from '../consts/action-types';

const defaultState = {
    items: {
        Hits: [],
        TotalCount: 0
    },
    filters : [],
    query: '',
    loading: false,
    take: 10
};

export const search = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SEARCH__LOADING:
            state = { ...state, loading: action.payload };
            break;
        case actionTypes.SEARCH__GET:
            state = {
                ...state,
                query: action.payload.query,
                items: action.payload.items,
                filters: action.payload.filters,
                take: action.payload.take,
                loading: false
            };
            break;
    }
    return state;
}