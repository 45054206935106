import React, { useState, useEffect, Fragment } from "react";
import RecursiveItem from "./MobileItem";
import classNames from "classnames";

let MobileItem = ({ page, level, open }) => {
  let childPage = page.page != undefined ? page.page : page;

  let childPages = page.childPages;
  let isActive = childPage.isActive;
  let isCurrentDocument = childPage.isCurrentDocument;

  let ulClasses =
    isActive || isCurrentDocument
      ? "visible-node mobile-navigation__container__level-" + level
      : "mobile-navigation__container__level-" + level;
  return (
    <li>
      <a
        tabIndex={open ? "0" : "-1"}
        href={childPage.nodeAliasPath}
        style={
          isCurrentDocument ? { fontWeight: "bold" } : { fontWeight: "normal" }
        }
      >
        {childPage.documentName}
      </a>
      {childPages.length ? (
        <Fragment>
          <a
            className="icon-plusminus js-toggle-mobile"
            href="#"
            tabIndex={open ? "0" : "-1"}
            aria-expanded={isActive || isCurrentDocument ? "true" : "false"}
          >
            <i
              className={
                isActive || isCurrentDocument ? "icon-minus" : "icon-plus"
              }
            ></i>
          </a>
          <ul
            className={ulClasses}
            aria-hidden={
              isCurrentDocument || isActive || open ? "false" : "true"
            }
          >
            {childPages.map((e, i) => (
              <RecursiveItem key={i} page={e} level={level + 1} />
            ))}
          </ul>
        </Fragment>
      ) : null}
    </li>
  );
};

export default MobileItem;
