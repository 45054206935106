import React, {useState, useEffect, useRef, Fragment} from 'react';
import classNames from 'classnames';
import Step from './Step';

let TimeLine = ({steps, current}) => {

    return (
        <div className="job-form__timeline">
            {
                steps.map((step, i) => {
                    let key = i + step.question;
                    let id = step.id;

                    return step.question.length ? <Step key={key} timer={key} id={id} current={current} /> : null
                }) 
            }
        </div>
    );
};

export default TimeLine;