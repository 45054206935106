import React, { Fragment } from "react";
//import classes from './CourseList.css'
import Course from "./partials/Course";

const CourseList = ({ courseList }) => {
  return courseList.map((course, index) => {
    let key = index + course.Title;
    let isMeetup = course.Type === "Knowit.CoursePage";
    return <Course {...course} isMeetup={isMeetup} id={index} key={index} />;
  });
};

export default CourseList;
