import React, { useState, useEffect, useRef, Fragment } from "react";
import Select, { components } from "react-select";
import chroma from "chroma-js";
import makeAnimated from "react-select/animated";
import classNames from "classnames";
import { PulseLoader } from "react-spinners";
import request from "superagent";
import Promise from "promise";
import styled from "styled-components";
import { Checkbox } from "./Checkbox";
let SubscribeBlock = ({
  header,
  nameText,
  emailText,
  categoryHeader,
  buttonText,
  disclaimer,
  categories,
  thankyouHeader,
  thankyouText,
  errorText,
  errorHeader,
  namePlaceholder,
  nameValidationText,
  emailPlaceholder,
  emailValidationText,
  categoryPlacerholder,
  categoeryNoOptionsText,
  categoryValidationText,
  workform,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [catList, setCatList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState(thankyouText);
  const [responseHeader, setResponseHeader] = useState(thankyouHeader);
  const [ok, setOk] = useState(false);
  const [consent, setConsent] = useState(false);

  const wrapperElm = useRef(null);
  const okElm = useRef(null);

  let loaderClasses = classNames({
    "subscribe-block__wrapper__loading": true,
    "is-active": loading,
  });

  const animatedComponents = makeAnimated();

  let getCatOptions = () => {
    let options = [];
    categories.forEach((cat) => {
      options.push({
        value: cat.Key,
        label: cat.Value,
      });
    });
    return options;
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma("#0095f5");
      return {
        ...styles,
        color: color,
        ":hover": {
          color: color,
        },
        cursor: isDisabled ? "not-allowed" : "pointer",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? color : color.alpha(0.3).css()),
        },
      };
    },
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
    }),
    noOptionsMessage: (base, state) => ({
      ...base,
      color: "white",
    }),
    multiValue: (styles, { data }) => {
      const color = chroma("#0095f5");
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#0095f5",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#0095f5",
      ":hover": {
        backgroundColor: "#0095f5",
        color: "white",
      },
    }),
  };

  let handleDropdownChange = (val) => {
    let errorSpan = document.getElementById("category-error");

    if (val != null) {
      setCatList(val);
      errorSpan.innerText = "";
      errorSpan.style.opacity = 0;
    } else {
      setCatList([]);
      errorSpan.innerHTML = "Du måste fylla i minst en kategori";
      errorSpan.style.opacity = 1;
    }
  };

  let onCatBlur = (e) => {
    let errorSpan = document.getElementById("category-error");
    triggerTracking("interaction", "category");

    if (catList.length < 1) {
      errorSpan.style.opacity = 1;
      errorSpan.innerHTML = "Du måste fylla i minst en kategori";
    } else {
      errorSpan.innerText = "";
      errorSpan.style.opacity = 0;
    }
  };
  let onInputChange = (e, type) => {
    let id = e.target.id;
    let value = e.target.value;
    let errorMessage = e.target.dataset.error;
    let errorSpan = document.getElementById(id + "-error");
    let element = document.getElementById(id);
    let error = false;
    switch (type) {
      case "email":
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(value)) error = true;
        triggerTracking("interaction", "email");

        setEmail(error ? "" : value);
        break;
      case "name":
        if (!value.length) error = true;
        triggerTracking("interaction", "name");

        setName(error ? "" : value);
        break;
      case "category":
        if (!value.length) error = true;
      default:
        break;
    }

    handleError(error, element, errorSpan, errorMessage);
  };

  let handleError = (isError, element, errorSpan, errorMessage) => {
    let errorClass = "error";
    if (isError) {
      if (!element.classList.contains(errorClass))
        element.classList.add(errorClass);
      errorSpan.innerText = errorMessage;
    } else {
      if (element.classList.contains(errorClass))
        element.classList.remove(errorClass);
      errorSpan.innerText = "";
    }
  };

  let handleSubmit = () => {
    if (!name.length || !email.length || !catList.length) return false;
    setLoading(true);
    let params = {
      url: "/SubscribeForJobApi/CreateJobAdPageEmail",
      name: name,
      email: email,
      categories: catList,
    };

    let targetUrl = `${params.url}?name=${params.name}&email=${email}`;

    if (params.categories.length) {
      targetUrl = targetUrl + "&categories=";
      params.categories.forEach((element) => {
        targetUrl = targetUrl + element.value + ",";
      });
    }

    return new Promise((resolve, reject) => {
      request
        .get(targetUrl)
        .set("Accept", "application/json")
        .end((error, response) => {
          if (response.ok) {
            setLoading(false);
            setOk(true);
          } else {
            setResponseHeader(errorHeader);
            setResponseText(errorText);
            setLoading(false);
            setOk(true);
          }
        });
    });
  };

  let wrapperClasses = classNames({
    "subscribe-block__wrapper": true,
  });

  let okClasses = classNames({
    "subscribe-block__wrapper__ok": true,
    "grid-780": true,
  });
  const Link = styled.a`
    margin: 5px 0;
    font-size: 14px;
    float: right;
    border-bottom: 1px solid transparent;
    transition: 0.3s ease all;
    @media (max-width: 350px) {
      margin-top: 20px;
    }

    &:hover {
      border-color: inherit;
      transition: 0.3s ease all;
    }
  `;
  const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `;

  useEffect(() => {
    if (wrapperElm.current != null && workform != "True") {
      wrapperElm.current.parentElement.classList.add("is-active");
    }
  }, [categories]);

  const triggerTracking = (action, field) => {
    window.dataLayer = window.dataLayer || [];
    let cats = catList.map((cat, i) => cat.label);
    switch (action) {
      case "submit":
        dataLayer.push({
          event: "formSubmit",
          formName: "Prenumerera Jobb",
          jobbIntresse: cats,
        });
        break;
      case "interaction":
        dataLayer.push({
          event: "formInteraction",
          formName: "Prenumerera Jobb",
          formField: field,
          jobbIntresse: "",
        });
        break;
    }
  };
  useEffect(() => {
    if (ok) {
      triggerTracking("submit");
    }
    setTimeout(() => {
      if (okElm.current != null) {
        okElm.current.parentElement.classList.add("ok-active");
      }
    }, 300);
  }, [ok]);

  const handleConsent = (e) => {
    setConsent(!consent);
  };
  return (
    <div className={wrapperClasses} ref={wrapperElm}>
      <div className={loaderClasses}>
        <PulseLoader
          size={15}
          sizeUnit={"px"}
          color={"#0d97f2"}
          loading={loading}
        />
      </div>
      {ok ? (
        <div className={okClasses} ref={okElm}>
          <h3 className="subscribe-block__wrapper__ok__header">
            {responseHeader}
          </h3>
          <p className="subscribe-block__wrapper__ok__text">{responseText}</p>
        </div>
      ) : (
        <Fragment>
          <div className="subscribe-block__wrapper__left">
            <div className="subscribe-block__wrapper__left__upper">
              <p className="subscribe-block__wrapper__left__upper__disclaimer">
                {disclaimer}
              </p>
            </div>
            <div className="subscribe-block__wrapper__left__bottom">
              <p className="subscribe-block__wrapper__left__bottom__label subscribe-label">
                {nameText}
              </p>
              <p className="subscribe-block__wrapper__left__bottom__label subscribe-label">
                {emailText}
              </p>
            </div>
          </div>
          <div className="subscribe-block__wrapper__right">
            <div className="subscribe-block__wrapper__right__upper">
              <h3 className="subscribe-block__wrapper__right__upper__header">
                {header}
              </h3>
            </div>
            <div className="subscribe-block__wrapper__right__bottom">
              <label className="subscribe-block__wrapper__right__upper__label subscribe-label">
                {nameText}
              </label>
              <input
                type="text"
                id="name"
                data-error={nameValidationText}
                aria-label={namePlaceholder}
                placeholder={namePlaceholder}
                onBlur={(e) => onInputChange(e, "name")}
              />
              <span id="name-error" className="error-span"></span>
              <label className="subscribe-block__wrapper__right__upper__label subscribe-label">
                {emailText}
              </label>
              <input
                type="text"
                id="email"
                data-error={emailValidationText}
                aria-label="Email"
                placeholder={emailPlaceholder}
                className="short"
                onBlur={(e) => onInputChange(e, "email")}
              />
              <span id="email-error" className="error-span"></span>

              <div className="subscribe-block__wrapper__right__bottom__category-wrapper">
                <p className="subscribe-block__wrapper__right__bottom__category-wrapper__header">
                  {categoryHeader}
                </p>
              </div>
              <Select
                options={getCatOptions()}
                isMulti
                closeMenuOnSelect={false}
                components={animatedComponents}
                placeholder={categoryPlacerholder}
                aria-label={categoryPlacerholder}
                required={true}
                className="basic-multi-select"
                classNamePrefix="basic-multi"
                noOptionsMessage={() => {
                  categoeryNoOptionsText;
                }}
                onBlur={(e) => onCatBlur(e)}
                styles={colourStyles}
                onChange={(val) => handleDropdownChange(val)}
              />
              <span className="error-span" id="category-error"></span>
              <TextWrapper>
                <Checkbox
                  consent={consent}
                  handleConsent={handleConsent}
                ></Checkbox>
                <Link target="_blank" href="/gdpr">
                  Läs mer om AISABs hantering av personuppgifter
                </Link>
              </TextWrapper>

              <input
                disabled={!consent}
                type="submit"
                onClick={() => handleSubmit()}
                id="submit"
                value={buttonText}
              />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SubscribeBlock;
