let lazyLoad = {
  init() {
    let images = document.querySelectorAll(".lazyload");

    new LazyLoad(images, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });
  },
};

export default lazyLoad;
