import React, { useState, useEffect, useRef, Fragment } from "react";
import classNames from "classnames";
import { PulseLoader } from "react-spinners";
import request from "superagent";
import Promise from "promise";

import Question from "./partials/Question";
import TimeLine from "./partials/TimeLine";
import Result from "./views/Result";

import resultJson from "./json/result.json";

let JobFormBlock = ({
  backgroundColor,
  questions,
  header,
  startButtonText,
  nodeAliasPath,
  failedHeader,
  failedText,
  lastApplyText,
}) => {
  const [testStarted, setTestStarted] = useState(false);
  const [testEnded, setTestEnded] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [validQuestions, setValidQuestions] = useState([]);

  const [question1, setQuestion1] = useState({
    answer: "",
    correct: false,
    id: 1,
  });
  const [question2, setQuestion2] = useState({
    answer: "",
    correct: false,
    id: 2,
  });
  const [question3, setQuestion3] = useState({
    answer: "",
    correct: false,
    id: 3,
  });
  const [question4, setQuestion4] = useState({
    answer: "",
    correct: false,
    id: 4,
  });
  const [question5, setQuestion5] = useState({
    answer: "",
    correct: false,
    id: 5,
  });

  const formElm = useRef(null);
  const [result, setResult] = useState(resultJson);
  const [failedTextList, setFailedTextList] = useState([]);

  let loaderClasses = classNames({
    "job-form__loading": true,
    "is-active": loading,
  });

  let startTest = () => {
    setTestStarted(true);
    setCurrentStep(1);
  };

  let endTest = () => {
    setTestEnded(true);
    let subscribeForm = formElm.current.parentElement.nextElementSibling;
    if (
      subscribeForm != undefined &&
      subscribeForm.classList.contains("job-form__subscribe-block")
    ) {
      subscribeForm.classList.add("is-active");
      subscribeForm.firstElementChild.classList.add("is-active");
    }
  };

  let submitTest = () => {
    var targetUrl = `/WorkForm/GetPageList?q1=${question1.answer}&q2=${question2.answer}&q3=${question3.answer}&q4=${question4.answer}&q5=${question5.answer}&path=${nodeAliasPath}`;
    setLoading(true);
    return new Promise((resolve, reject) => {
      request
        .get(targetUrl)
        .set("Accept", "application/json")
        .end((error, response) => {
          if (response.ok) {
            setLoading(false);
            setResult(response.body);

            // window.dataLayer = window.dataLayer || [];
            // dataLayer.push({
            //   event: "formSubmit",
            //   formName: "Prenumerera Jobb",
            //   jobbIntresse: "Chef, HR",
            // });

            endTest();
          } else {
            setLoading(false);
            endTest();
          }
        });
    });
  };

  useEffect(() => {
    if (formElm.current != null) {
      formElm.current.classList.add("is-active");
    }
    setValidQuestions(questions.filter(({ question }) => question.length));
  }, [questions]);

  useEffect(() => {
    if (question5.answer != "") {
      submitTest();
    }
  }, [question5]);

  let optionClick = (answer, id, correct, answerText) => {
    if (!correct) setFailedTextList(failedTextList.concat(answerText));

    switch (id) {
      case 1:
        setQuestion1({ answer: answer, correct: correct, id: id });
        break;
      case 2:
        setQuestion2({ answer: answer, correct: correct, id: id });
        break;
      case 3:
        setQuestion3({ answer: answer, correct: correct, id: id });
        break;
      case 4:
        setQuestion4({ answer: answer, correct: correct, id: id });
        break;
      case 5:
        setQuestion5({ answer: answer, correct: correct, id: id });
        break;
    }

    if (currentStep < validQuestions.length) {
      setCurrentStep(currentStep + 1);
    } else {
      submitTest();
    }
  };

  return (
    <div
      className="job-form"
      style={{ backgroundColor: backgroundColor }}
      ref={formElm}
    >
      <div className={loaderClasses}>
        <PulseLoader
          size={15}
          sizeUnit={"px"}
          color={"#017acb"}
          loading={loading}
        />
      </div>
      <div className="job-form__wrapper grid-1280">
        <h2 className="job-form__header">{header}</h2>
        {testStarted ? (
          <Fragment>
            <TimeLine steps={validQuestions} current={currentStep} />
            <div className="job-form__questions">
              {testEnded
                ? null
                : validQuestions.map((question, i) => {
                    let key = i + question.question;
                    let show = currentStep == question.id;
                    let prev = currentStep - 1 == question.id;
                    return question.question.length ? (
                      <Question
                        {...question}
                        key={key}
                        show={show}
                        optionClick={optionClick}
                      />
                    ) : null;
                  })}
            </div>
          </Fragment>
        ) : (
          <input
            type="button"
            className="job-form-button job-form__button"
            value={startButtonText}
            onClick={() => startTest()}
          />
        )}
        {testEnded ? (
          <Result
            {...result}
            failedTextList={failedTextList}
            failedHeader={failedHeader}
            lastApplyText={lastApplyText}
            failedText={failedText}
            submitTest={() => submitTest()}
          />
        ) : null}
      </div>
    </div>
  );
};

export default JobFormBlock;
