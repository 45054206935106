import React from "react";
//import classes from './pauseButton.css'

const pauseButton = ({ pauseText }) => {
  return (
    <div className="start-page-wrapper__top-image__pause grid-1280">
      <button
        aria-label="Pausa filmen"
        className="start-page-wrapper__top-image__pause__btn"
        id="play-pause-button"
        data-playing="true"
      >
        <i id="play-pause-icon" className="icon-pause"></i>
        <span id="play-pause-text">{pauseText}</span>
      </button>
    </div>
  );
};

export default pauseButton;
