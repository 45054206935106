import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { searchActions } from "../../actions";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";

import classNames from "classnames";

import SearchFilters from "./_partials/SearchFilters";
import SearchBar from "./_partials/SearchBar";
import SearchResults from "./_partials/SearchResults";

let ModuleSearch = ({ search }) => {
  const [items, setItems] = useState(search.items);
  const [filters, setFilters] = useState(search.filters);
  const [activeFilters, setActiveFilters] = useState("");
  const [query, setQuery] = useState(search.query);
  const [queryParam, setQueryParam] = useQueryParam("query", StringParam);
  const [take, setTake] = useState(search.take);
  const dispatch = useDispatch();

  useEffect(() => {
    loadItems();
    if (query.length) {
      setQueryParam(query);
    }
  }, [query, activeFilters, take]);

  useEffect(() => {
    setFilters(search.filters);
  }, [search.filters]);

  let loadItems = () => {
    dispatch(
      searchActions.get({
        query: query,
        filters: activeFilters,
        take: take,
      })
    );
  };

  let handleFilters = (activeFilterString) => {
    setActiveFilters(activeFilterString);
  };

  useEffect(() => {
    if (queryParam != undefined) {
      setQuery(queryParam);
    }
  }, []);

  return (
    <Fragment>
      <SearchBar
        typeOfSearch={search.items.typeOfSearch}
        totalCount={search.items.TotalCount}
        searchText={query}
        onChange={(e) => setQuery(e)}
        loading={search.loading}
      />
      <SearchFilters
        filters={filters}
        onChange={(activeFilters, filterName) =>
          handleFilters(activeFilters, filterName)
        }
      />
      <SearchResults
        items={search.items}
        take={take}
        loading={search.loading}
        changeTake={() => {
          setTake(take + 10);
        }}
      />
    </Fragment>
  );
};

function select(store) {
  return {
    ...store,
  };
}

export default connect(select)(ModuleSearch);
