import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import SearchFilter from './SearchFilter';

let SearchFilters = ({filters, onChange}) => {
    const [ filterQueryList, setFilterQueryList ] = useState([]);

    useEffect(() => {
        let queryList = [];
        filters.map((filter, i) => {
            let filterQuery = `?${filter.value}=`; 

            {filter.buttons.map((button, i) => {
                const { active, value } = button;
                if (active) filterQuery += `${value}, `;
            })}

            queryList = queryList.concat(filterQuery);
        })
        setFilterQueryList(queryList);
    }, [filters]);

    let removeByValue = (array, value) => {
        return array.filter((elem, _index) => {
            return value != elem;
      });
    }

    let onFilterClick = (activeFilters, filterName) => {
        let list = filterQueryList;
        filterQueryList.map((filter, i) => {
            if (filter.indexOf(filterName) > -1) {
                list = removeByValue(list, filter);
                let filterQuery = `?${filterName}=`; 
                activeFilters.map((value, i) => {
                    filterQuery += `${value}, `;
                });
                list = list.concat(`${filterQuery}`);
            }
        });

        let stringToReturn = '';
        list.map((value, i) => {
            stringToReturn += value;
        });
        onChange(stringToReturn);
        setFilterQueryList(list);
    }

    let wrapperClasses = classNames({
        'filterbutton-wrapper': true,
        'is-active': filters.length
    });

    return (
        <div className={wrapperClasses}>
            <div className="filterbutton-wrapper-inner grid-1280">
                <div className="filterbutton-wrapper-inner__margin-correction">
                    {filters.map((filter, i) => {
                        return <SearchFilter {...filter} onChange={(activeFilters, filterName) => onFilterClick(activeFilters, filterName)} key={i} />
                    })}
                    
                </div>
            </div>
        </div>
    );   
};

export default SearchFilters;