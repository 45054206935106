import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

const getDeviceConfig = (width) => {
  if (width >= 0 && width < 650) {
    return "mobile";
  } else if (width >= 650 && width < 900) {
    return "ipad";
  } else if (width >= 900 && width < 1249) {
    return "desktop";
  } else if (width >= 1250) {
    return "large";
  }
};

const breakpoints = ["mobile", "ipad", "desktop", "large"];

const useBreakpoint = (breakpointToCompare, behaviour = "") => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  if (behaviour === "") {
    return breakpointToCompare === brkPnt;
  }

  if (behaviour !== "") {
    if (breakpointToCompare === brkPnt) {
      return true;
    } else {
      const currentIndex = breakpoints.indexOf(brkPnt);
      const compareIndex = breakpoints.indexOf(breakpointToCompare);

      if (behaviour === "down") {
        return currentIndex < compareIndex;
      }

      if (behaviour === "up") {
        return currentIndex > compareIndex;
      }
    }
  }

  return false;
};

export default useBreakpoint;
