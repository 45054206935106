import React, { useState, useEffect, useRef } from "react";
import Step from "./partials/Step";

let PatientFlow = ({ pages, header }) => {
  const [active, setActive] = useState(false);
  const pagesRef = useRef(null);
  const wrapperElm = useRef(null);
  useEffect(() => {
    if ("IntersectionObserver" in window) {
      let lazyBackgroundObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            setTimeout(() => {
              setActive(true);
            });
            lazyBackgroundObserver.unobserve(entry.target);
          }
        });
      });
      if (pagesRef.current != undefined) {
        lazyBackgroundObserver.observe(pagesRef.current);
      }
    }
  }, []);

  useEffect(() => {
    if (wrapperElm.current != null) {
      wrapperElm.current.parentElement.classList.add("loaded");
    }
  }, [pages]);

  return (
    <div className="patient-sbs-wrapper grid-1280" ref={wrapperElm}>
      <div className="patient-sbs-wrapper__top">
        <h2 ref={pagesRef}>Patientresan</h2>
      </div>
      <div>
        {active &&
          pages.map((step, i) => {
            let key = i + step.header;
            return <Step {...step} key={key} id={i} />;
          })}
      </div>
    </div>
  );
};

export default PatientFlow;
