import React, { useState, useEffect, Fragment } from "react";
import classNames from "classnames";
import { PulseLoader } from "react-spinners";
import MenuBox from "./partials/MenuBox";

let MenuReact = ({ Pages, device }) => {
  const [firstTopPage, setFirstTopPage] = useState({});
  const [secondTopPage, setSecondTopPage] = useState({});
  const [thirdTopPage, setThirdTopPage] = useState({});
  const [fourthTopPage, setFourthTopPage] = useState({});
  const [fifthTopPage, setFifthTopPage] = useState({});

  const [firstTopList, setFirstTopList] = useState([]);
  const [secondTopList, setSecondTopList] = useState([]);
  const [thirdTopList, setThirdTopList] = useState([]);
  const [fourthTopList, setFourthTopList] = useState([]);
  const [fifthTopList, setFifthTopList] = useState([]);
  const [firstTopPageNodeOrder, setFirstTopPageNodeOrder] = useState("");
  const [secondTopPagNodeOrder, setSecondTopPageNodeOrder] = useState("");
  const [thirdTopPageNodeOrder, setThirdTopPageNodeOrder] = useState("");
  const [fourthTopPageNodeOrder, setFourthTopPageNodeOrder] = useState("");
  const [fifthTopPageNodeOrder, setFifthTopPageNodeOrder] = useState("");
  const [currentDocument, setCurrentDocument] = useState(false);
  useEffect(() => {
    let firstLevelPages = Pages.filter(({ nodeLevel }) => nodeLevel == 1);

    firstLevelPages.forEach((e, i) => {
      let key = i + 1;
      let page = e;

      if (page.isCurrentDocument) setCurrentDocument(true);
      switch (key) {
        case 1:
          setFirstTopPage(page);
          setFirstTopPageNodeOrder(page.strNodeOrder);
          break;
        case 2:
          setSecondTopPage(page);
          setSecondTopPageNodeOrder(page.strNodeOrder);
          break;
        case 3:
          setThirdTopPage(page);
          setThirdTopPageNodeOrder(page.strNodeOrder);
          break;
        case 4:
          setFourthTopPage(page);
          setFourthTopPageNodeOrder(page.strNodeOrder);
          break;
        case 5:
          if (device != "desktop") {
            setFifthTopPage(page);
            setFifthTopPageNodeOrder(page.strNodeOrder);
          }

          break;

        default:
          break;
      }
    });
  }, [Pages]);

  useEffect(() => {
    let menuItems = Pages.filter(({ nodeLevel }) => nodeLevel > 1);
    let firstList = [];
    let secondList = [];
    let thirdList = [];
    let fourthList = [];
    let fifthList = [];

    menuItems.forEach((e, i) => {
      let page = e;

      if (
        firstTopPageNodeOrder.length &&
        page.strNodeOrder.includes(firstTopPageNodeOrder)
      ) {
        firstList.push(page);
      } else if (
        secondTopPagNodeOrder.length &&
        page.strNodeOrder.includes(secondTopPagNodeOrder)
      ) {
        secondList.push(page);
      } else if (
        thirdTopPageNodeOrder.length &&
        page.strNodeOrder.includes(thirdTopPageNodeOrder)
      ) {
        thirdList.push(page);
      } else if (
        fourthTopPageNodeOrder.length &&
        page.strNodeOrder.includes(fourthTopPageNodeOrder)
      ) {
        fourthList.push(page);
      } else if (
        fifthTopPageNodeOrder.length &&
        page.strNodeOrder.includes(fifthTopPageNodeOrder)
      ) {
        fifthList.push(page);
      }
    });
    if (firstList.length) setFirstTopList(firstList);
    if (secondList.length) setSecondTopList(secondList);
    if (thirdList.length) setThirdTopList(thirdList);
    if (fourthList.length) setFourthTopList(fourthList);
    if (fifthList.length) setFifthTopList(fifthList);
  }, [
    firstTopPageNodeOrder,
    secondTopPagNodeOrder,
    thirdTopPageNodeOrder,
    fourthTopPageNodeOrder,
    fifthTopPageNodeOrder,
  ]);

  return (
    <Fragment>
      {device == "desktop" ? (
        <div className="menu-content-left">
          <div className="menu-content-left__box-wrapper">
            {firstTopPageNodeOrder.length ? (
              <MenuBox
                pages={firstTopList}
                topPage={firstTopPage}
                device={device}
              />
            ) : null}
            {secondTopPagNodeOrder.length ? (
              <MenuBox
                pages={secondTopList}
                topPage={secondTopPage}
                device={device}
              />
            ) : null}
            {thirdTopPageNodeOrder.length ? (
              <MenuBox
                pages={thirdTopList}
                topPage={thirdTopPage}
                device={device}
              />
            ) : null}
            {fourthTopPageNodeOrder.length ? (
              <MenuBox
                pages={fourthTopList}
                topPage={fourthTopPage}
                device={device}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <ul className="mobile-navigation__container__level-2">
          {firstTopPageNodeOrder.length ? (
            <MenuBox
              pages={firstTopList}
              topPage={firstTopPage}
              device={device}
              isOpen={currentDocument}
            />
          ) : null}
          {secondTopPagNodeOrder.length ? (
            <MenuBox
              pages={secondTopList}
              topPage={secondTopPage}
              device={device}
              isOpen={currentDocument}
            />
          ) : null}
          {thirdTopPageNodeOrder.length ? (
            <MenuBox
              pages={thirdTopList}
              topPage={thirdTopPage}
              device={device}
              isOpen={currentDocument}
            />
          ) : null}
          {fourthTopPageNodeOrder.length ? (
            <MenuBox
              pages={fourthTopList}
              topPage={fourthTopPage}
              device={device}
              isOpen={currentDocument}
            />
          ) : null}
          {fifthTopPageNodeOrder.length ? (
            <MenuBox
              pages={fifthTopList}
              topPage={fifthTopPage}
              device={device}
              isOpen={currentDocument}
            />
          ) : null}
        </ul>
      )}
    </Fragment>
  );
};

export default MenuReact;
