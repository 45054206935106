import { setTabIndices } from "../../../components/c-functions/c-functions";
const mHeader = {
  init() {
    var headerNullCheck = document.querySelectorAll("header");
    if (headerNullCheck !== null) {
      // Button IDs
      var searchLink = document.getElementById("search-link");
      var langLink = document.getElementById("lang-link");
      var innerSearchLink = document.querySelectorAll(
        ".search-box-header__inner__link-row__heading-wrapper"
      );

      // Content box - Search
      let searchBoxContainer = document.querySelector(".search-box-wrapper");
      let hiddenSearch = searchBoxContainer.getAttribute("aria-hidden");

      let searchBox = document.querySelector(".search-box-header");
      let searchArrow = document.querySelector(
        ".search-box-header__inner__arrow"
      );

      // Content box - Language
      let langBox = document.querySelector(".lang-box-header");
      let langArrow = document.querySelector(".lang-box-header__inner__arrow");
      let hiddenLang = langBox.getAttribute("aria-hidden");

      // var for main
      let mainMargin = document.querySelector("main");
      var prevScrollpos = window.pageYOffset;
      // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
      window.onscroll = function () {
        // Get the header
        var header = document.querySelector("header");
        var test = window.pageYOffset;
        // Get the offset position of the navbar
        var sticky = header.offsetTop;
        if (window.pageYOffset > sticky) {
          if (test > 400) {
            header.classList.add("sticky-header");
          } else {
            header.classList.remove("sticky-header");
          }
        }
        // Hide/Show header on scroll on phone
        if (/Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)) {
          var intPosFromTop = window.pageYOffset;
          let searchBarOpen =
            searchBoxContainer.getAttribute("aria-hidden") == "false";
          let langBarOpen = langBox.getAttribute("aria-hidden") == "false";

          let top = searchBarOpen ? "-256px" : "-80px";

          if (intPosFromTop > 200) {
            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
              document.querySelector("header").style.top = "0";
            } else {
              document.querySelector("header").style.top = top;
            }
            prevScrollpos = currentScrollPos;
          }
        }
      };
      setTabIndices(searchBoxContainer, hiddenSearch === "true");
      setTabIndices(langBox, hiddenLang === "true");

      //Function Search
      searchLink.onclick = function () {
        let hiddenSearch = searchBoxContainer.getAttribute("aria-hidden");

        const togglePhoneNav = document.querySelector(".hamburger-menu-phone");
        const phoneNav = document.querySelector(".mobile-navigation");
        setTabIndices(searchBoxContainer, hiddenSearch === "false");

        if (hiddenSearch === "true") {
          mainMargin.style.transitionDelay = "0.2s";
          mainMargin.classList.remove("lang-margin");

          //Close the language box before opening search
          mainMargin.classList.add("main-margin");
          langBox.style.transitionDelay = "0s";
          langBox.classList.remove("expanded-header");

          langLink.setAttribute("aria-expanded", "false");
          langBox.setAttribute("aria-hidden", "true");
          langArrow.style.opacity = "0";
          // Close the menu
          phoneNav.style.height = "0vh";
          togglePhoneNav.setAttribute("aria-expanded", "false");
          phoneNav.setAttribute("aria-hidden", "true");
          document.body.classList.remove("no-scroll");
          //Opening search
          searchBox.style.transitionDelay = "0.2s";
          searchBox.classList.add("expanded-header");
          this.setAttribute("aria-expanded", "true");
          searchBoxContainer.setAttribute("aria-hidden", "false");
          searchArrow.style.opacity = "1";
          document
            .getElementsByClassName(
              "search-box-header__inner__content__form__input"
            )[0]
            .focus();

          if (/Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)) {
            document.body.classList.add("no-scroll");
          }
        } else {
          //Close search if you click on the search button again
          mainMargin.style.transitionDelay = "0s";

          mainMargin.classList.remove("main-margin");
          searchBox.style.transitionDelay = "0s";
          searchBox.classList.remove("expanded-header");
          this.setAttribute("aria-expanded", "false");
          searchBoxContainer.setAttribute("aria-hidden", "true");
          searchArrow.style.opacity = "0";
          document.body.classList.remove("no-scroll");
        }
      };

      //Function Lang
      langLink.onclick = function () {
        let hiddenLang = langBox.getAttribute("aria-hidden");
        setTabIndices(langBox, hiddenLang === "false");

        if (hiddenLang === "true") {
          mainMargin.style.transitionDelay = "0.2s";
          if (langBox != undefined) {
            langBox.getElementsByTagName("a")[0].focus();
            let activeNode = langBox.getElementsByClassName("active")[0];
            if (activeNode != undefined) {
              activeNode.style.backgroundColor = "#0e3c7c";
              activeNode.setAttribute("aria-disabled", true);
              activeNode.setAttribute("href", "#");
            }
          }

          // langBox.getElementsByClassName("active").focus();
          const phoneNav = document.querySelector(".mobile-navigation");
          const togglePhoneNav = document.querySelector(
            ".hamburger-menu-phone"
          );

          phoneNav.style.height = "0vh";
          togglePhoneNav.setAttribute("aria-expanded", "false");
          phoneNav.setAttribute("aria-hidden", "true");
          document.body.classList.remove("no-scroll");
          mainMargin.classList.add("main-margin", "lang-margin");
          //Close the search box before opening search
          searchBox.style.transitionDelay = "0s";
          searchBox.classList.remove("expanded-header");
          searchLink.setAttribute("aria-expanded", "false");
          searchBoxContainer.setAttribute("aria-hidden", "true");
          searchArrow.style.opacity = "0";
          //Opening language
          langBox.style.transitionDelay = "0.2s";
          langBox.classList.add("expanded-header");
          this.setAttribute("aria-expanded", "true");
          langBox.setAttribute("aria-hidden", "false");
          langArrow.style.opacity = "1";
        } else {
          //Close language if you click on the search button again

          mainMargin.classList.remove("main-margin", "lang-margin");
          searchBox.style.transitionDelay = "0s";
          langBox.classList.remove("expanded-header");
          this.setAttribute("aria-expanded", "false");
          langBox.setAttribute("aria-hidden", "true");
          langArrow.style.opacity = "0";
        }
      };
    }
  },
};

export default mHeader;
