"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var serialize_query_params_1 = require("serialize-query-params");
var QueryParamProvider_1 = require("./QueryParamProvider");
var updateUrlQuery_1 = require("./updateUrlQuery");
/**
 * Given a query param name and query parameter configuration ({ encode, decode })
 * return the decoded value and a setter for updating it.
 *
 * The setter takes two arguments (newValue, updateType) where updateType
 * is one of 'replace' | 'replaceIn' | 'push' | 'pushIn', defaulting to
 * 'replaceIn'.
 *
 * You may optionally pass in a rawQuery object, otherwise the query is derived
 * from the location available in the QueryParamContext.
 *
 * D = decoded type
 * D2 = return value from decode (typically same as D)
 */
exports.useQueryParam = function (name, paramConfig, rawQuery) {
    var _a;
    if (paramConfig === void 0) { paramConfig = serialize_query_params_1.StringParam; }
    var _b = React.useContext(QueryParamProvider_1.QueryParamContext), history = _b.history, location = _b.location;
    // ref with current version history object (see #46)
    var refHistory = React.useRef(history);
    React.useEffect(function () {
        refHistory.current = history;
    }, [history]);
    // ref with current version location object (see #46)
    var refLocation = React.useRef(location);
    React.useEffect(function () {
        refLocation.current = location;
    }, [location]);
    // read in the raw query
    if (!rawQuery) {
        var locationIsObject_1 = typeof location === 'object';
        var windowIsDefined_1 = typeof window !== 'undefined';
        rawQuery = React.useMemo(function () {
            var pathname = {};
            // handle checking SSR (#13)
            if (locationIsObject_1) {
                // in browser
                if (windowIsDefined_1) {
                    pathname = serialize_query_params_1.parse(location.search);
                }
                else {
                    // not in browser
                    var url = location.pathname;
                    if (location.search) {
                        url += location.search;
                    }
                    pathname = serialize_query_params_1.parseUrl(url).query;
                }
            }
            return pathname || {};
        }, [location.search, location.pathname, locationIsObject_1, windowIsDefined_1]);
    }
    // read in the encoded string value
    var encodedValue = rawQuery[name];
    // note that we use the stringified encoded value since the encoded
    // value may be an array that is recreated if a different query param
    // changes. It is sufficient to use this instead of encodedValue in
    // the useMemo dependency array since it will change any time the actual
    // meaningful value of encodedValue changes.
    var arraySafeEncodedValue = encodedValue instanceof Array
        ? serialize_query_params_1.stringify((_a = {}, _a[name] = encodedValue, _a))
        : encodedValue;
    // decode if the encoded value has changed, otherwise
    // re-use memoized value
    var decodedValue = React.useMemo(function () {
        if (encodedValue == null) {
            return undefined;
        }
        return paramConfig.decode(encodedValue);
    }, [arraySafeEncodedValue, paramConfig]); // eslint-disable-line react-hooks/exhaustive-deps
    // create the setter, memoizing via useCallback
    var setValue = React.useCallback(function (newValue, updateType) {
        var _a;
        var newEncodedValue = paramConfig.encode(newValue);
        updateUrlQuery_1.updateUrlQuery((_a = {}, _a[name] = newEncodedValue, _a), refHistory.current.location || refLocation.current, // see #46 for why we use a ref here
        refHistory.current, updateType);
    }, [paramConfig, name]);
    return [decodedValue, setValue];
};
