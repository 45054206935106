import React, { useEffect, useState, Fragment } from "react";
//import classes from './CourseList.css'
import GetData from "./GetData";
import CourseList from "./CourseList";
import FilterBar from "./partials/FilterBar";
import classNames from "classnames";
import { PulseLoader } from "react-spinners";

const CourseListPage = (props) => {
  const [filterBy, setFilterBy] = useState("GetAllCourses");
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let loaderClasses = classNames({
    "search-result-wrapper__loading": true,
    "is-active": isLoading,
  });

  useEffect(() => {
    setIsLoading(true);
    GetData.getData(filterBy)
      .then((res) => {
        setIsLoading(false);
        setCourses(res);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
      });
  }, [filterBy]);

  const handleClick = (e) => {
    setFilterBy(e.value);
  };

  return (
    <Fragment>
      <div className={loaderClasses}>
        <PulseLoader
          size={15}
          sizeUnit={"px"}
          color={"#0d97f2"}
          loading={isLoading}
        />
      </div>
      <div className="cl-inner grid-1280">
        <div className="cl-inner__control">
          <FilterBar value={filterBy} handleClick={handleClick}></FilterBar>
        </div>
        <CourseList
          loading={isLoading}
          type="typeOfSearch"
          courseList={courses}
        />
      </div>
    </Fragment>
  );
};

export default CourseListPage;
