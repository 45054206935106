import React, {useState, useEffect, useRef, Fragment} from 'react';
import classNames from 'classnames';
import Job from '../partials/Job';

let Result = ({header, text, secondHeader, jobs, educations, sendButtonText, submitTest, failedTextList, failedHeader, failedText, lastApplyText}) => {
    const [enter, setEnter] = useState(false);
    const [isFailed, setIsFailed] = useState(failedTextList.length)

    useEffect(() => {
        setTimeout(() => {
            setEnter(true);
        }, 300);
    })

    let classes = classNames({
        "enter": enter,
        "job-form__result-wrapper": true
    });

    return (
        <div className={classes}>
            <h4 className="job-form__result-wrapper__header">{isFailed ? failedHeader : header}</h4>
            <p className="job-form__result-wrapper__text">{isFailed ? failedText : text}</p>

            {
                failedTextList.length 
                    ?
                        <ul className="job-form__result-wrapper__list">
                        {    
                            failedTextList.map((text, i) => {
                                let key = i + text;
                                return text.length ? <li key={key}>{text}</li> : null;
                            }) 
                        }
                        </ul>
                    : jobs.length 
                        ?   <Fragment>
                                <h4 className="job-form__result-wrapper__header">{secondHeader}</h4>
                                {
                                    jobs.map((job, i) => {
                                        let key = i + job.header;
                                        let id = i * 200;
                                        return <Job {...job} lastApplyText={lastApplyText} key={key} id={id} />
                                    })         
                                }
                            </Fragment>
                        : null
                        
            }
            <input type="button" className="job-form-button job-form__result-wrapper__button" value={sendButtonText} onClick={() => submitTest()} />
        </div>
    );
};

export default Result;