import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { setTabIndices } from "../c-functions/c-functions";
export const FaqItem = ({ Answer, Question }) => {
  const [open, setOpen] = useState(false);
  let answerRef = useRef();

  useEffect(() => {
    setTabIndices(answerRef.current, !open);
  }, [open]);
  const handleClick = (e) => {
    setOpen(!open);
    const { current } = answerRef;
    current.style.height = !open ? current.scrollHeight + "px" : 0;
  };
  return (
    <Item>
      <QuestionButton open={open} onClick={(e) => handleClick(e)}>
        <H3>{Question}</H3>
        <Icon open={open}></Icon>
      </QuestionButton>
      <Child
        ref={answerRef}
        show={open}
        dangerouslySetInnerHTML={{ __html: Answer }}
      ></Child>
    </Item>
  );
};

const H3 = styled.h3`
  font-size: 25px;
  font-weight: 600;
  max-width: 90%;
  flex-basis: 90%;
`;
const QuestionButton = styled.button.attrs(({ show }) => ({
  "aria-expanded": open ? "true" : "false",
}))`
  color: #002554;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  -ms-flex-align: baseline;
  align-items: baseline;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.5rem;
  width: 100%;
  text-align: left;
  line-height: 1.4;
  margin-bottom: 7px;
  position: relative;
  outline: 0;
  background: #f2f7fb;
  @media (max-width: 640px) {
    padding: 1rem 1.5rem;
  }
`;
const Child = styled.div.attrs(({ show }) => ({
  "aria-hidden": show ? "false" : "true",
}))`
  padding: 0 24px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  overflow: hidden;
  margin-bottom: ${({ show }) => (show ? "15px" : "")};
  transition: 0.2s ease-out;
  height: 0;
  max-width: 750px;
`;
const Icon = styled.i`
  position: absolute;
  right: 15px;
  font-size: 20px;
  top: 15%;
  flex-basis: 10%;

  &:before {
    content: "";
    position: absolute;
    top: 22px;
    left: calc(100% - 28px);
    height: 11px;
    width: 2px;
    border-radius: 0;
    background-color: #002554;
    transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(135deg)")};
    transition: transform 0.2s ease-in;
  }

  &:after {
    content: "";
    position: absolute;
    top: 22px;
    left: calc(100% - 21px);
    height: 11px;
    width: 2px;
    border-radius: 0;
    background: #002554;
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(-135deg)")};
    transition: transform 0.2s ease-in, left 0.2s ease-in;
  }
  transition: transform 0.5s ease;
`;
const Item = styled.div``;
