const menuV2 = {
  init() {
    var menuLink = document.getElementById("hamburger-menu");

    // Button ID
    if (menuLink !== null) {
      let toggleMenu = () => {
        let hiddenMenu = menuBox.getAttribute("aria-hidden");

        if (hiddenMenu === "true") {
          //Opening menu
          menuBox.classList.add("expanded-menu");
          menuLink.setAttribute("aria-expanded", "true");
          menuBox.setAttribute("aria-hidden", "false");
        } else {
          //Close menu if you click on the menu button again
          menuBox.classList.remove("expanded-menu");
          menuLink.setAttribute("aria-expanded", "false");
          menuBox.setAttribute("aria-hidden", "true");
        }
      };
      var hiddenButton = document.getElementById("hidden-close-button");
      if (hiddenButton !== null) {
        hiddenButton.onclick = function () {
          toggleMenu();
        };
      }
      // Content box - Menu
      let menuBox = document.querySelector(".menu-box-header");
      //Function menu
      menuLink.onclick = function () {
        toggleMenu();
        if (!menuBox.classList.contains("expanded-menu")) {
          document.body.classList.add("no-scroll");
        } else {
          document.body.classList.remove("no-scroll");
        }
        if (!menuLink.classList.contains("initiated")) {
          // Expand child in menu

          var buttons = document.querySelectorAll(".expand-children-button");

          buttons.forEach((button, idx) => {
            button.addEventListener("click", (e) => {
              var expandedDiv = document.querySelectorAll(".expanded-children");

              let expanded = button.getAttribute("aria-expanded");

              if (expanded === "true") {
                button.setAttribute("aria-expanded", "false");
                button.classList.add("closed-container");
                button.classList.remove("open-container");
              } else {
                button.setAttribute("aria-expanded", "true");
                button.classList.remove("closed-container");
                button.classList.add("open-container");
              }

              expandedDiv.forEach((content, contentIdx) => {
                if (contentIdx === idx) {
                  let hidden = content.getAttribute("aria-hidden");

                  if (hidden === "true") {
                    content.setAttribute("aria-hidden", "false");

                    content.querySelectorAll("button").forEach((button) => {
                      button.setAttribute("tabindex", "0");
                    });
                    content.querySelectorAll("a").forEach((anchor) => {
                      anchor.setAttribute("tabindex", "0");
                    });
                  } else {
                    content.setAttribute("aria-hidden", "true");
                    content.querySelectorAll("a").forEach((anchor) => {
                      anchor.setAttribute("tabindex", "-1");
                    });
                    content.querySelectorAll("button").forEach((button) => {
                      button.setAttribute("tabindex", "-1");
                    });
                  }
                }
              });
            });
          });
          menuLink.classList.add("initiated");
        }
      };
    }
  },
};

export default menuV2;
