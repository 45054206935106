let FOCUSABLE_ELEMENTS = ["a", "input", "button"];

export let cFunctions = {
  init() {
    addLabelsToExternalLinks();
    // WrapQuote();
  },
};
const addLabelsToExternalLinks = () => {
  let externalLinks = document.querySelectorAll(
    ".content-editorial a[target='_blank']"
  );
  if (externalLinks.length > 0 && externalLinks != undefined) {
    externalLinks.forEach((link, i) => {
      link.setAttribute("aria-label", "Öppnas i nytt fönster");
    });
  }
};

export const setTabIndices = (parentElement, shouldFocus) => {
  if (parentElement != undefined) {
    Array.prototype.slice
      .call(parentElement.querySelectorAll(FOCUSABLE_ELEMENTS.join(",")))
      .forEach((el, i) => {
        if (shouldFocus) {
          el.setAttribute("tabIndex", "-1");
        } else {
          el.setAttribute("tabIndex", "0");
        }
      });
  }
};
const WrapQuote = () => {
  Array.prototype.slice
    .call(document.querySelectorAll("q"))
    .forEach((element) => {
      var wrapper = document.createElement("div");
      wrapper.classList.add("q-wrapper");
      element.parentNode.insertBefore(wrapper, element);

      wrapper.appendChild(element);
    });
};
