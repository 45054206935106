"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var serialize_query_params_1 = require("serialize-query-params");
var useQueryParam_1 = require("./useQueryParam");
var updateUrlQuery_1 = require("./updateUrlQuery");
var QueryParamProvider_1 = require("./QueryParamProvider");
// from https://usehooks.com/usePrevious/
function usePrevious(value) {
    var ref = React.useRef(value);
    React.useEffect(function () {
        ref.current = value;
    }, [value]);
    return ref.current;
}
// from https://github.com/lodash/lodash/issues/2340#issuecomment-360325395
function isShallowEqual(objA, objB) {
    for (var key in objA)
        if (!(key in objB) || objA[key] !== objB[key])
            return false;
    for (var key in objB)
        if (!(key in objA) || objA[key] !== objB[key])
            return false;
    return true;
}
/**
 * Given a query parameter configuration (mapping query param name to { encode, decode }),
 * return an object with the decoded values and a setter for updating them.
 */
exports.useQueryParams = function (paramConfigMap) {
    var _a = React.useContext(QueryParamProvider_1.QueryParamContext), history = _a.history, location = _a.location;
    var locationIsObject = typeof location === 'object';
    // memoize paramConfigMap to make the API nicer for consumers.
    // otherwise we'd have to useQueryParams(useMemo(() => { foo: NumberParam }, []))
    var prevParamConfigMap = usePrevious(paramConfigMap);
    var hasNewParamConfig = isShallowEqual(prevParamConfigMap, paramConfigMap);
    // prettier-ignore
    var memoParamConfigMap = React.useMemo(function () { return paramConfigMap; }, [
        hasNewParamConfig,
    ]);
    paramConfigMap = memoParamConfigMap;
    // ref with current version history object (see #46)
    var refHistory = React.useRef(history);
    React.useEffect(function () {
        refHistory.current = history;
    }, [history]);
    // ref with current version location object (see #46)
    var refLocation = React.useRef(location);
    React.useEffect(function () {
        refLocation.current = location;
    }, [location]);
    var search = locationIsObject ? location.search : '';
    // read in the raw query
    var rawQuery = React.useMemo(function () { return serialize_query_params_1.parse(search) || {}; }, [
        search,
    ]);
    // parse each parameter via useQueryParam
    // we reuse the logic to not recreate objects
    var paramNames = Object.keys(paramConfigMap);
    var paramValues = paramNames.map(function (paramName) {
        return useQueryParam_1.useQueryParam(paramName, paramConfigMap[paramName], rawQuery)[0];
    });
    // we use a memo here to prevent recreating the containing decodedValues object
    // which would break === comparisons even if no values changed.
    var decodedValues = React.useMemo(function () {
        // iterate over the decoded values and build an object
        var decodedValues = {};
        for (var i = 0; i < paramNames.length; ++i) {
            decodedValues[paramNames[i]] = paramValues[i];
        }
        return decodedValues;
    }, paramValues); // eslint-disable-line react-hooks/exhaustive-deps
    // create a setter for updating multiple query params at once
    var setQuery = React.useCallback(function (changes, updateType) {
        // encode as strings for the URL
        var encodedChanges = serialize_query_params_1.encodeQueryParams(paramConfigMap, changes);
        // update the URL
        updateUrlQuery_1.default(encodedChanges, refHistory.current.location || refLocation.current, // see #46
        refHistory.current, updateType);
    }, [paramConfigMap]);
    // no longer Partial
    return [decodedValues, setQuery];
};
exports.default = exports.useQueryParams;
