const cSkip = {
  init() {
    var skipButton = document.getElementById('skipButton');
    if (skipButton !== null) {
      skipButton.onclick = function () {
        document.getElementById("mainContent").focus();
      }
    }
  },
};

export default cSkip;
