import { setTabIndices } from "../c-functions/c-functions";

const menuPhone = {
  init() {
    const toggleMobileNavigation = document.querySelector(
      ".hamburger-menu-phone"
    );
    const mobileNavigation = document.querySelector(".mobile-navigation");

    toggleMobileNavigation.addEventListener("click", () => {
      if (toggleMobileNavigation.getAttribute("aria-expanded") === "false") {
        mobileNavigation.style.height = "calc(100vh - 80px)";
        toggleMobileNavigation.setAttribute("aria-expanded", "true");
        mobileNavigation.setAttribute("aria-hidden", "false");
        document.body.classList.add("no-scroll");
        hideSearch();
      } else {
        mobileNavigation.style.height = "0vh";
        toggleMobileNavigation.setAttribute("aria-expanded", "false");
        mobileNavigation.setAttribute("aria-hidden", "true");
        document.body.classList.remove("no-scroll");
      }

      if (!toggleMobileNavigation.classList.contains("initiated")) {
        const levelToggle = document.querySelectorAll(".js-toggle-mobile");

        levelToggle.forEach((button) => {
          button.addEventListener("click", () => {
            let isExpanded = button.getAttribute("aria-expanded");

            if (isExpanded === "false") {
              if (
                button.parentElement.parentElement.classList.contains(
                  "mobile-navigation__container__level-3"
                )
              ) {
                button.previousElementSibling.classList.add("active-level-3");
                button.firstChild.classList.add("active-level-3__icon");
              }

              if (
                button.parentElement.parentElement.classList.contains(
                  "mobile-navigation__container__level-4"
                )
              ) {
                button.previousElementSibling.classList.add("active-level-4");
                button.firstChild.classList.add("active-level-4__icon");
              }
              if (
                button.parentElement.parentElement.classList.contains(
                  "mobile-navigation__container__level-5"
                )
              ) {
                button.previousElementSibling.classList.add("active-level-5");
                button.firstChild.classList.add("active-level-5__icon");
              }
              if (
                button.parentElement.parentElement.classList.contains(
                  "mobile-navigation__container__level-6"
                )
              ) {
                button.previousElementSibling.classList.add("active-level-6");
                button.firstChild.classList.add("active-level-6__icon");
              }
              let childrenList = button.nextElementSibling;
              let grandchildren = childrenList.children;

              Array.prototype.slice.call(grandchildren).forEach((el, i) => {
                el.querySelector("a").setAttribute("tabindex", "0");

                if (el.querySelector(".js-toggle-mobile") != null) {
                  el.querySelector(".js-toggle-mobile").setAttribute(
                    "tabindex",
                    "0"
                  );
                }
              });

              childrenList.classList.add("visible-node");
              childrenList.setAttribute("aria-hidden", "false");

              button.setAttribute("aria-expanded", "true");
              button.firstElementChild.classList.remove("icon-plus");
              button.firstElementChild.classList.add("icon-minus");
            } else {
              if (
                button.parentElement.parentElement.classList.contains(
                  "mobile-navigation__container__level-3"
                )
              ) {
                button.previousElementSibling.classList.remove(
                  "active-level-3"
                );
              }
              if (
                button.parentElement.parentElement.classList.contains(
                  "mobile-navigation__container__level-4"
                )
              ) {
                button.previousElementSibling.classList.remove(
                  "active-level-4"
                );
              }
              if (
                button.parentElement.parentElement.classList.contains(
                  "mobile-navigation__container__level-5"
                )
              ) {
                button.previousElementSibling.classList.remove(
                  "active-level-5"
                );
              }
              if (
                button.parentElement.parentElement.classList.contains(
                  "mobile-navigation__container__level-6"
                )
              ) {
                button.previousElementSibling.classList.remove(
                  "active-level-6"
                );
              }

              let childrenList = button.nextElementSibling;
              childrenList.classList.remove("visible-node");

              childrenList.setAttribute("aria-hidden", "true");
              let grandchildren = childrenList.children;

              Array.prototype.slice.call(grandchildren).forEach((el, i) => {
                el.querySelector("a").setAttribute("tabindex", "-1");
                if (el.querySelector(".js-toggle-mobile") != null) {
                  el.querySelector(".js-toggle-mobile").setAttribute(
                    "tabindex",
                    "-1"
                  );
                }
              });

              button.setAttribute("aria-expanded", "false");
              button.firstElementChild.classList.remove("icon-minus");
              button.firstElementChild.classList.add("icon-plus");
            }
          });
        });
        //Open language on mobile

        let phoneLang = document.querySelectorAll(".phone-lang");

        phoneLang.forEach((button, idx) => {
          button.addEventListener("click", (e) => {
            var contents = document.querySelectorAll(
              ".mobile-navigation__container__lang-wrapper"
            );
            let expanded = button.getAttribute("aria-expanded");

            if (expanded === "true") {
              button.setAttribute("aria-expanded", "false");
            } else {
              button.setAttribute("aria-expanded", "true");
            }

            contents.forEach((content, contentIdx) => {
              if (contentIdx === idx) {
                let hidden = content.getAttribute("aria-hidden");

                if (hidden === "true") {
                  content.setAttribute("aria-hidden", "false");
                } else {
                  content.setAttribute("aria-hidden", "true");
                }
              } else {
                contents[contentIdx].setAttribute("aria-hidden", "true");
              }
            });
          });
        });
        toggleMobileNavigation.classList.add("initiated");
      }
    });

    function hideSearch() {
      var searchLink = document.getElementById("search-link");

      let searchBox = document.querySelector(".search-box-header");
      let searchArrow = document.querySelector(
        ".search-box-header__inner__arrow"
      );
      //Close search if you click on the search button again
      searchBox.style.transitionDelay = "0s";
      searchBox.classList.remove("expanded-header");
      searchLink.setAttribute("aria-expanded", "false");
      searchBox.setAttribute("aria-hidden", "true");
      searchArrow.style.opacity = "0";
    }
  },
};

export default menuPhone;
