import React from "react";
import styled from "styled-components";
import { FaqItem } from "./FaqItem";
export const FAQBlock = (props) => {
  const { Header, Pages } = props;

  return (
    <Wrapper className="grid-1280">
      <h2>{Header}</h2>
      {Pages.map((item, i) => {
        return <FaqItem key={i} {...item} />;
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 40px 15px;
  h2 {
    padding: 20px 0;
  }
  @media (min-width: 900px) {
    padding: 55px 60px;
  }
`;
