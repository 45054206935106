import "./polyfills";
import mountHeroBlock from "../pages/p-start/hero-react/mount-hero-block";
import mHeader from "./modules/module-header/module-header";
import cSkip from "../components/c-skip-to-content/c-skip-to-content";
import cCookie from "../components/c-cookiebar/c-cookiebar";
//import cSlider from '../components/c-slider/c-slider';
import cMenuV2 from "../components/c-menu-v2/c-menu-v2";
import cMenuPhone from "../components/c-menu-phone/c-menu-phone";
import mountcSubscribe from "../components/c-subscribe-block/mount-subscribe-block";
import mountJobForm from "../components/c-job-form-block/mount-job-form-block";
import mountMenu from "../components/c-menu-react/mount-c-menu-react";
import mountPatientFlow from "../components/c-patient-sbs/mount-component-patient-flow";
import AppReactHandler from "./js/app.reactHandler";
import playButtonStart from "../pages/p-start/p-start";
import playButtonStandard from "../pages/p-standard/p-standard";
import mountSearchBlock from "../components/c-header-search/mount-search";
import mountCourseListingBlock from "../components/c-course-list/mount-course-list";
import mountCourseWidget from "../components/c-course-list/mount-course-widget";
import mountFaqBlock from "../components/c-faq-block/mount-faq-block";

// import mountSvg from "../components/c-start-icons/mountSvg";
import mountIcons from "../components/c-icon-link-block/mount-icons";
import { cFunctions } from "../components/c-functions/c-functions";
import mountNewsArchivePage from "../pages/p-news-archive/mount-archive";

import lazyLoad from "./js/lazyLoadInit";

const appReactHandler = new AppReactHandler();

(function (funcName, baseObj) {
  funcName = funcName || "docReady";
  baseObj = baseObj || window;
  var readyList = [];
  var readyFired = false;
  var readyEventHandlersInstalled = false;

  function ready() {
    if (!readyFired) {
      readyFired = true;
      for (var i = 0; i < readyList.length; i++) {
        readyList[i].fn.call(window, readyList[i].ctx);
      }
      readyList = [];
    }
  }

  function readyStateChange() {
    if (document.readyState === "complete") {
      ready();
    }
  }

  baseObj[funcName] = function (callback, context) {
    if (typeof callback !== "function") {
      throw new TypeError("callback for docReady(fn) must be a function");
    }
    if (readyFired) {
      setTimeout(function () {
        callback(context);
      }, 1);
      return;
    } else {
      readyList.push({ fn: callback, ctx: context });
    }
    if (document.readyState === "complete") {
      setTimeout(ready, 1);
    } else if (!readyEventHandlersInstalled) {
      if (document.addEventListener) {
        document.addEventListener("DOMContentLoaded", ready, false);
        window.addEventListener("load", ready, false);
      } else {
        document.attachEvent("onreadystatechange", readyStateChange);
        window.attachEvent("onload", ready);
      }
      readyEventHandlersInstalled = true;
    }
  };
})("docReady", window);

docReady(function () {
  window.onresize = (event) => {};
});
mountHeroBlock.init();
lazyLoad.init();
appReactHandler.init();
mountMenu.init();
cSkip.init();
cCookie.init();
mountcSubscribe.init();
mountJobForm.init();
mHeader.init();
mountPatientFlow.init();
cMenuV2.init();
cMenuPhone.init();
playButtonStart.init();
playButtonStandard.init();
mountSearchBlock.init();
mountCourseListingBlock.init();
mountCourseWidget.init();
mountNewsArchivePage.init();
cFunctions.init();
mountFaqBlock.init();
