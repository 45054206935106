import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import useBreakpoint from "../../../framework/hooks/useBreakpoint";

let Step = ({ header, text, icon, id, youtubevideo, title }) => {
  const [active, setActive] = useState(false);
  let mobile = useBreakpoint("mobile");
  useEffect(() => {
    if ("IntersectionObserver" in window && mobile) {
      let lazyBackgroundObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            setTimeout(() => {
              setActive(true);
            }, 500);
            lazyBackgroundObserver.unobserve(entry.target);
          }
        });
      });
      if (imageRef.current != undefined) {
        lazyBackgroundObserver.observe(imageRef.current);
      }
    } else {
      setTimeout(() => {
        setActive(true);
      }, id * 600);
    }
  }, []);

  const imageRef = useRef();
  // useEffect(() => {
  //   setTimeout(() => {
  //     setActive(true);
  //   }, id * 700);
  // }, []);

  let getYoutubeId = (url) => {
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return "";
    }
  };

  let youtubeId = getYoutubeId(youtubevideo);
  let youtubeSrc = `//www.youtube.com/embed/${youtubeId}`;
  let iconUrl = icon.indexOf("~") > -1 ? icon.replace(/~/gi, "") : icon;

  let stepClassName = classNames({
    "patient-sbs-wrapper__step": true,
    "has-video": youtubeId.length,
    "is-active": active,
    "is-first": id == 0,
  });
  return youtubeId.length ? (
    <div ref={imageRef} className={stepClassName}>
      <div className="patient-sbs-wrapper__step__divider"></div>
      <div className="patient-sbs-wrapper__step__number">
        <h3>{id + 1}</h3>
      </div>
      <div className="patient-sbs-wrapper__step__inner">
        <div className="patient-sbs-wrapper__step__inner__content">
          <div className="icon-wrapper">
            <div
              className="icon-wrapper__icon"
              style={{ backgroundImage: `url(${iconUrl})` }}
            ></div>
          </div>
          <div className="has-video__text-wrapper">
            <h4>{header}</h4>
            <div
              className="patient-sbs-wrapper__step__inner__content__expandable"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          </div>
        </div>
        <div className="patient-sbs-wrapper__step__inner__content">
          <p className="patient-sbs-wrapper__step__inner__content__video-wrapper">
            <iframe
              title={title.length > 0 ? title : header}
              showinfo="0"
              width="100%"
              height="100%"
              src={youtubeSrc}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div ref={imageRef} className={stepClassName}>
      <div className="patient-sbs-wrapper__step__divider"></div>
      <div className="patient-sbs-wrapper__step__number">
        <h3>{id + 1}</h3>
      </div>
      <div className="patient-sbs-wrapper__step__inner">
        <div className="icon-wrapper">
          <div
            className="icon-wrapper__icon"
            style={{ backgroundImage: `url(${iconUrl})` }}
          ></div>
        </div>
        <div className="patient-sbs-wrapper__step__inner__content">
          <h4>{header}</h4>
          <div
            className="patient-sbs-wrapper__step__inner__content__expandable"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Step;
