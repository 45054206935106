import React, { useState, useEffect, useRef, Fragment } from "react";
import classNames from "classnames";

let Step = ({ timer, id, current }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, id * 400);
  }, []);

  let firstStep = id == 1;
  let classes = classNames({
    current: current >= id,
    "job-form__timeline__step": true,
    first: firstStep,
    "is-active": active,
  });
  let numberClasses = classNames({
    "job-form__timeline__step__number": true,
    "is-active": active,
  });

  return (
    <div key={timer} className={classes}>
      {id == 1 ? null : <div className="line"></div>}
      <p className={numberClasses}>{id}</p>
    </div>
  );
};

export default Step;
