import React from "react";

const SuggestionsList = ({ suggestions, userInput }) => {
  let inputWidth = document.getElementById(
    "search-box-header__inner_content__form__input"
  ).offsetWidth;
  return suggestions.length ? (
    <ul style={{ width: inputWidth }} className="suggestions">
      {suggestions.map((suggestion, index) => {
        let header = suggestion.header;
        var reg = new RegExp(userInput, "gi");
        var final_str = header.replace(reg, function (str) {
          return "<b>" + str + "</b>";
        });

        return (
          <li role="option" key={index}>
            <a tabindex="0" href={suggestion.url}>
              <span dangerouslySetInnerHTML={{ __html: final_str }}></span>
            </a>
            <div className="suggestions__catlist">
              <div className="suggestions__catlist__name">{suggestion.tag}</div>
            </div>
            <span class="suggestions__border"></span>
          </li>
        );
      })}
    </ul>
  ) : null;
};

export default SuggestionsList;
