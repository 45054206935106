import styled from "styled-components";
import React, { useState } from "react";

export const CheckBoxStyle = styled.input`
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;

  &:checked ~ label {
    &:before {
      border: 2px solid #0e3c7c;
    }
    &:after {
      transform: rotate(-45deg) scale(1);
    }
  }
  &:focus + label::before {
    outline: 0;
  }
`;
export const CheckboxWrapper = styled.div`
  width: 100%;
  /* margin: 15px auto; */
  padding: 20px 0;
  padding-bottom: 0;

  position: relative;
  display: block;
`;
export const CheckBoxLabel = styled.label`
  position: relative;
  min-height: 34px;
  width: 100%;
  margin-left: 0 !important;
  display: flex !important;
  padding-left: 40px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  a {
    float: right;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 22px;
    height: 22px;
    transition: transform 0.2s ease;
    border-radius: 3px;
    border: 2px solid #0e3c7c;
  }
  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 5px;
    border-bottom: 2px solid #0e3c7c;
    border-left: 2px solid #0e3c7c;
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.2s;
    position: absolute;
    top: 12px;
    left: 10px;
  }
`;

export const Checkbox = ({ consent, handleConsent }) => {
  return (
    <CheckboxWrapper>
      <CheckBoxStyle
        type="checkbox"
        id="gdpr"
        title="Inga uppgifter kommer användas för marknadsföring eller säljas vidare till tredje part."
        checked={consent}
        onChange={handleConsent}
        name="gdpr"
        value=""
      ></CheckBoxStyle>
      <CheckBoxLabel htmlFor="gdpr">
        <span>
          Jag samtycker till att AISAB registrerar ovanstående uppgifter i
          enlighet med GDPR.
        </span>
      </CheckBoxLabel>
    </CheckboxWrapper>
  );
};
