import React from "react";
import { render } from "react-dom";
import CourseListPage from "./CourseListPage";
import CourseList from "./CourseList";

let mountCourseWidget = {
  append(root) {
    if (!root) return;
    const _root = root[0] != undefined ? root[0] : root;
    const html = _root.innerText != "" ? _root.innerText : _root.textContent;
    let props = JSON.parse(html);

    const component = render(
      <CourseList courseList={props} {...props} />,
      _root
    );
    return component;
  },

  init() {
    let courseListingBlock = document.getElementsByClassName(
      "course-list-widget"
    );
    if (!courseListingBlock.length) return false;
    if (courseListingBlock.length > 1) {
      for (var i = 0; i < courseListingBlock.length; i++) {
        this.append(courseListingBlock[i]);
      }
    } else {
      this.append(courseListingBlock);
    }
    this.append(courseListingBlock);
  },
};

export default mountCourseWidget;
