import $ from "jquery";

const cCookie = {
  init() {
    var cookieNullCheck = document.querySelectorAll(".cookie-wrapper");
    if (cookieNullCheck !== null && cookieNullCheck.length) {
      var hideCookie = document.getElementById("hide-cookie-button");

      /*Show inner content */
      var buttons = document.querySelectorAll(".cookiemenubtn");

      buttons.forEach((button, idx) => {
        button.addEventListener("click", (e) => {
          var contents = document.querySelectorAll(
            ".detail__container-inner__right"
          );

          let expanded = button.getAttribute("aria-expanded");

          buttons.forEach((button, idx) => {
            button.setAttribute("aria-expanded", "false");
          });

          if (expanded === "true") {
            button.setAttribute("aria-expanded", "false");
          } else {
            button.setAttribute("aria-expanded", "true");
          }

          contents.forEach((content, contentIdx) => {
            if (contentIdx === idx) {
              let hidden = content.getAttribute("aria-hidden");

              if (hidden === "true") {
                content.setAttribute("aria-hidden", "false");
              } else {
                content.setAttribute("aria-hidden", "true");
              }
            } else {
              contents[contentIdx].setAttribute("aria-hidden", "true");
            }
          });
        });
      });

      // Hide cookie menu
      var hideCookie = document.getElementById("hide-cookie-button");

      hideCookie.onclick = function () {
        var preferencesChecked = document.getElementById("toggle-two").checked;
        var statisticsCookiesChecked = document.getElementById("toggle-three")
          .checked;
        var marketingCookiesChecked = document.getElementById("toggle-four")
          .checked;

        let cookieWrapper = document.querySelector(".cookie-wrapper");
        let cookieMenu = cookieWrapper.getAttribute("aria-hidden");

        if (cookieMenu === "true") {
          cookieWrapper.setAttribute("aria-hidden", "false");
        } else {
          //Close menu if you click on the menu button again
          cookieWrapper.setAttribute("aria-hidden", "true");
        }
        var CookieChecks = {
          PreferenceCookiesAccepted: preferencesChecked,
          StatisticsCookiesAccepted: statisticsCookiesChecked,
          MarketingCookiesChecked: marketingCookiesChecked,
        };
        var json = JSON.stringify(CookieChecks);

        $.ajax({
          async: true,
          type: "POST",
          data: { json: json },
          url: "/TrackingConsent/AcceptedCookies",
          success: function () {},
          error: function () {},
        }).then(() => {
          location.reload();
        });
      };
    }
  },
};

export default cCookie;
