﻿import React from "react";
import { render } from "react-dom";
import SubscribeBlock from "./c-subscribe-block";

let MountSubscribe = {
    append(root) {
        if (!root) return;
        const _root = root[0] != undefined ? root[0] : root;
        const html = _root.innerText != "" ? _root.innerText : _root.textContent;
        let props = JSON.parse(html);

        const component = render(<SubscribeBlock {...props} />, _root);
        return component;
    },

    init() {
        let subscribeBlocks = document.getElementsByClassName("subscribe-block");
        if (!subscribeBlocks.length) return false;
        if (subscribeBlocks.length > 1) {
            for (var i = 0; i < subscribeBlocks.length; i++) {
                this.append(subscribeBlocks[i]);
            }
        } else {
            this.append(subscribeBlocks);
        }
    }
};

export default MountSubscribe;
