import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import Item from "./Item";
import MobileItem from "./MobileItem";
import classNames from "classnames";
import styled from "styled-components";

let MenuBox = ({ topPage, pages, device }) => {
  const [pageList, setPageList] = useState([]);
  const {
    documentName,
    iconName,
    nodeAliasPath,
    categoryClass,
    isCurrentDocument,
    isActive,
  } = topPage;
  let GetSubMenu = (page, pages) => {
    let subList = [];
    pages.forEach((e) => {
      let child = e;
      if (child.parentNodeId == page.nodeId) {
        let childObj = {
          page: child,
          childPages: GetSubMenu(child, pages),
        };
        subList.push(childObj);
      }
    });
    return subList;
  };
  const Icon = styled.i`
    margin: ${({ margin }) => (margin ? margin : "0 10px 0 0")};
    font-size: 20px;
    color: white;
  `;

  let topWrapperClasses =
    categoryClass != undefined && categoryClass.length
      ? "menu-content-left__box-wrapper__box__inner__top-wrapper " +
        `color-${categoryClass}`
      : "menu-content-left__box-wrapper__box__inner__top-wrapper";

  let iconClasses =
    isCurrentDocument || isActive
      ? "menu-content-left__box-wrapper__box__inner__top-wrapper__plusminus icon-minus-1"
      : "menu-content-left__box-wrapper__box__inner__top-wrapper__plusminus icon-plus-1";

  useLayoutEffect(() => {
    let newPageList = [];
    pages.forEach((e, i) => {
      let page = e;
      if (page.nodeLevel == 2) {
        let pageObj = {
          page: page,
          childPages: GetSubMenu(page, pages),
        };

        newPageList.push(pageObj);
      }
    });
    if (newPageList.length) {
      setPageList(newPageList);
    }
  }, [pages]);

  return (
    <Fragment>
      {device == "desktop" ? (
        <div className="menu-content-left__box-wrapper__box">
          <div className="menu-content-left__box-wrapper__box__inner">
            <div
              className={topWrapperClasses}
              data-active={isCurrentDocument || isActive ? "true" : "false"}
            >
              <a
                href={nodeAliasPath}
                className="menu-content-left__box-wrapper__box__inner__top-wrapper__toggle"
              >
                <Icon className={`menu-item-icon ${iconName}`}></Icon>
                <span> {documentName} </span>
                <Icon margin="0 0 0 10px" className="icon-angle-right"></Icon>
              </a>
            </div>
            <div className="menu-content-left__box-wrapper__box__inner__link-wrapper">
              {pageList.map((e, i) => {
                let key = i;
                let pageObj = e;
                let page = e.page;
                let children = e.childPages;
                let buttonClass = page.isActive
                  ? "expand-children-button open-container tabindex-value"
                  : "expand-children-button closed-container tabindex-value";

                return children.length ? (
                  <Fragment key={key}>
                    <div className="expandable-inner-link">
                      <a
                        href={page.nodeAliasPath}
                        style={
                          page.isCurrentDocument
                            ? { fontWeight: "bold" }
                            : { fontWeight: "normal" }
                        }
                      >
                        {page.documentName}
                      </a>
                      <button
                        className={buttonClass}
                        aria-expanded={page.isActive ? "true" : "false"}
                      ></button>
                    </div>
                    <div
                      className="expanded-children"
                      aria-hidden={page.isActive ? "false" : "true"}
                      // tabIndex="-1"
                    >
                      {children.map((e, i) => (
                        <Item key={i} page={e} />
                      ))}
                    </div>
                  </Fragment>
                ) : (
                  <a
                    href={page.nodeAliasPath}
                    key={key}
                    style={
                      page.isCurrentDocument
                        ? { fontWeight: "bold" }
                        : { fontWeight: "normal" }
                    }
                    className="menu-content-left__box-wrapper__box__inner__link-wrapper__link tabindex-value"
                    // tabIndex="-1"
                  >
                    {page.documentName}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <li className={categoryClass}>
          <a href={nodeAliasPath}>{documentName}</a>
          {pages.length ? (
            <a
              className="icon-plusminus js-toggle-mobile"
              href="#"
              aria-expanded={isActive || isCurrentDocument ? "true" : "false"}
            >
              <i
                className={
                  isActive || isCurrentDocument ? "icon-minus" : "icon-plus"
                }
              ></i>
            </a>
          ) : null}
          <ul
            className={
              isActive || isCurrentDocument
                ? "mobile-navigation__container__level-3 visible-node"
                : "mobile-navigation__container__level-3"
            }
            aria-hidden={isActive ? "false" : "true"}
          >
            {pageList.map((e, i) => {
              let key = i;
              let level = key + 3;
              return (
                <MobileItem
                  open={isActive || isCurrentDocument}
                  key={key}
                  page={e}
                  level={level}
                />
              );
            })}
          </ul>
        </li>
      )}
    </Fragment>
  );
};

export default MenuBox;
