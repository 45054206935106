import React, { Fragment } from "react";

const SearchInput = ({ userInput, onClick, onChange, onKeyDown }) => {
  return (
    <input
      id="search-box-header__inner_content__form__input"
      autoComplete="off"
      aria-label="Sök"
      placeholder="Vad söker du?"
      tabIndex="-1"
      name="query"
      type="search"
      className="search-box-header__inner__content__form__input"
      onChange={onChange}
      onClick={onClick}
      onKeyDown={onKeyDown}
      value={userInput}
    />
  );
};

export default SearchInput;
