import React from 'react';
import { useState } from 'react';
import classNames from 'classnames';

let FilterButton = ({active, value, count, title, onClick}) => {
    const [ isActive, setIsAtive ] = useState(active);

    let filterClass = classNames({
        'filterbutton-wrapper-inner__filter__btn-wrapper__btn': true,
        'active': isActive
    });

    let onFilterClick = () => {
        let filterOjbect = {
            key: value,
            val: !isActive
        }
        onClick(filterOjbect);
        setIsAtive(!isActive);
    }
    return (
        <button className={filterClass} onClick={(e) => onFilterClick()}>
            <b>{title}</b> ({count})
        </button>)    
};

export default FilterButton;